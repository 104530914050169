import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NoAttachedFiles = () => {
  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ height: '120px' }}
    >
      <FontAwesomeIcon icon={faDownload} size="xl" />
      <span className="upload-button">Drag and drop files here</span>
      <span className="upload-button">or browse</span>
    </div>
  );
};

export default NoAttachedFiles;
