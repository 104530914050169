const toBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = error => reject(error);
  });
};

export const filesBase64 = async (form, files, type) => {
  const delKeys = Object.keys(form);
  delKeys.forEach(key => {
    if (key.includes('image') && type === 'img') {
      delete form[key];
    }
    if (key.includes('document') && type === 'doc') {
      delete form[key];
    }

    if (key.includes('render') && type === 'rdr') {
      delete form[key];
    }
    if (key.includes('document') && type === 'frl') {
      delete form['document01ISfile'];
    }
  });

  const result = {};
  await Promise.all(
    Array.from(files).map(async (file, index) => {
      const base64 = await toBase64(file);

      let key;
      if (type === 'img') {
        key = `image0${index + 1}ISfile`;
      }
      if (type === 'doc') {
        key = `document0${index + 1}ISfile`;
      }
      if (type === 'rdr') {
        key = `render0${index + 1}ISfile`;
      }
      if (type === 'flr') {
        key = 'document01ISfile';
      }
      if (type === 'docs') {
        key = `document0${index + 2}ISfile`;
      }
      result[key] = {
        name: file.name,
        type: file.type,
        data: base64
      };

      form = { ...form, ...result };
    })
  );

  return form;
};
