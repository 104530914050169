import { useEffect, useMemo, useState } from 'react';
import Loading from 'components/loading/index';
import { useSelector } from 'react-redux';
import { useGetContentMutation } from 'utils/rest/content/getContent';
import { useTranslation } from 'react-i18next';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import CardDropdown from 'components/common/CardDropdown';
import { Dropdown } from 'react-bootstrap';
import { getIcon } from 'helpers/utils';

const MyContent = () => {
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();
  const { mutate: getContent, isPending, isSuccess } = useGetContentMutation();
  const { content } = useSelector(state => state.content);

  const ActionButtons = ({ data }) => {
    const { _id } = data;
    return (
      <CardDropdown iconClassName="fs--1">
        <div className="py-2">
          <Dropdown.Item
            onClick={e => {
              e.preventDefault(e);
              console.log('here');
            }}
          >
            <span>
              {getIcon({
                icon_component: 'ri/RiEditBoxFill',
                icon_component_attributes: { fontSize: 16, marginRight: 10 }
              })}
            </span>
            <span>{t('Edit')}</span>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={e => {
              e.preventDefault(e);
              console.log('here');
            }}
          >
            <span>
              {getIcon({
                icon_component: 'md/MdDelete',
                icon_component_attributes: { fontSize: 16, marginRight: 10 }
              })}
            </span>
            <span>{t('Delete')}</span>
          </Dropdown.Item>
        </div>
      </CardDropdown>
    );
  };

  const columns = useMemo(() => {
    let result = [];
    result = [
      {
        accessor: 'name',
        Header: t('Name')
      },
      {
        accessor: 'date_added',
        Header: t('dateUploaded')
      },
      {
        accessor: 'status',
        Header: t('Status')
      },
      {
        accessor: 'action',
        Header: t('Action'),
        Cell: rowData => {
          const data = rowData.row.original;
          return (
            <div className="text-center">
              <ActionButtons data={data} />
            </div>
          );
        }
      }
    ];
    return result;
  }, [content]);

  const contentData = useMemo(() => {
    let results = [];
    results = content?.map(c => {
      const date = c._dateadded.split('T')[0].replace(/-/g, '/');
      const time = c._dateadded.split('T')[1].split('+')[0];
      return {
        _id: c._id,
        name: c.name,
        date_added: `${date} ${time}`,
        status: c.status ? c.status : 'Waiting for approval'
      };
    });
    return results;
  }, [content]);

  useEffect(() => {
    if (isSuccess) {
      setLoading(false);
    }
    if (isPending) {
      setLoading(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    getContent();
  }, []);

  if (loading)
    return (
      <Loading style={{ marginTop: 0, height: '100%' }} className="d-flex" />
    );

  return (
    <div style={{ height: '100%' }}>
      {!content ||
        (content.length === 0 && (
          <div className="d-flex justify-content-center content-border">
            <span className="fs-18">
              You have no content submitted - click 'Add Content' to start
            </span>
          </div>
        ))}
      {content && content.length > 0 && (
        <div className="extended-table">
          <AdvanceTableWrapper
            columns={columns}
            data={contentData}
            sortable
            pagination
            perPage={10}
          >
            <AdvanceTable
              table
              headerClassName="bg-200 text-700 text-nowrap align-middle fs-0"
              rowClassName="align-middle white-space-nowrap fs-0"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs--1 mb-0'
              }}
            />
            <div className="mt-3">
              <AdvanceTablePagination table />
            </div>
          </AdvanceTableWrapper>
        </div>
      )}
    </div>
  );
};
export default MyContent;
