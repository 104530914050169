import { Col, Form, Row } from 'react-bootstrap';

const FurtitureFixtures = ({ form, action }) => {
  const categories = [
    { id: '38239', name: 'Accessories' },
    { id: '38240', name: 'Booth' },
    { id: '38241', name: 'Mid Floor' },
    { id: '38242', name: 'Walls' },
    { id: '38243', name: 'Windows' }
  ];

  return (
    <Row>
      <Col>
        <fieldset className={'form-legend-item-wrapper mb-4'}>
          <legend className="item-title fs-1 fw-semi-bold">
            F&F Category:
          </legend>
          <Form.Control
            as="select"
            required
            className="form-select"
            value={form.category01ISbb_q4sg_content_categoriesID}
            name="category01ISbb_q4sg_content_categoriesID"
            onChange={action}
          >
            <option value="" disabled>
              Choose option
            </option>
            {categories.map((status, index) => (
              <option value={status.id} key={index} onChange={action}>
                {status.name}
              </option>
            ))}
          </Form.Control>
        </fieldset>
      </Col>
    </Row>
  );
};

export default FurtitureFixtures;
