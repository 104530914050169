import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  content: null
};

const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    setContentData(state, action) {
      state.content = action.payload;
    },
    resetContentData(state) {
      state.content = null;
    }
  }
});

export const { setContentData, resetContentData } = contentSlice.actions;

export default contentSlice.reducer;
