import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import https from 'utils/https';
import { ENDPOINTS } from 'utils/endpoints/endpoints';
import { logout } from 'redux/slicers/authSlice';
import { useNavigate } from 'react-router-dom';

async function logoutGet() {
  const { data } = await https.get(ENDPOINTS.LOGOUT);
  return data;
}

export const useLogoutMutation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: () => logoutGet(),
    onSuccess: async () => {
      await navigate('/login');
      await dispatch(logout());
    },
    onError: error => {
      console.log(error);
    }
  });
};
