import { useMutation } from '@tanstack/react-query';
import https from 'utils/https';
import { ENDPOINTS } from 'utils/endpoints/endpoints';
import ToastifyNotification from 'utils/toast/ToastifyNotification';

async function uploadContent(body) {
  const { data } = await https.post(ENDPOINTS.UPLOAD_CONTENT, body);
  return data;
}

export const useUploadContentMutation = () => {
  return useMutation({
    mutationFn: body => uploadContent(body),
    onSuccess: () => {
      ToastifyNotification({
        message: 'Your Content successfully uploaded',
        errorMessage: false
      });
    },
    onError: error => {
      console.log(error);
      if (error.response.data.error.includes('type')) {
        ToastifyNotification({
          message: error.response.data.error,
          errorMessage: true
        });
      } else
        ToastifyNotification({
          message: 'This "Content Name" already exists',
          errorMessage: true
        });
    }
  });
};
