import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Button, Col, Row, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/common/PageTitle';
import { useLoginMutation } from 'utils/rest/auth/login';
import { useSessionMutation } from 'utils/rest/auth/session';

const LoginForm = () => {
  const currentUrl = window.location.hostname;
  const title = "Let's get started";
  const forgottenText = 'Request a password reset link';
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });

  const { mutate: login, isPending } = useLoginMutation();
  const { mutate: session } = useSessionMutation();

  const handleSubmit = e => {
    e.preventDefault();
    login(formData, {
      onSuccess: async () => {
        session();
      }
    });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const passwordResetLink = useMemo(() => {
    if (currentUrl === 'dev.skytoolkit.net') {
      return 'https://skytoolkit-dev.blueboxonline.co.uk/?password_reset';
    } else if (currentUrl === 'www.skytoolkit.net') {
      return 'https://skytoolkit.blueboxonline.co.uk/?password_reset';
    }
    return 'https://skytoolkit-dev.blueboxonline.co.uk/?password_reset';
  }, [currentUrl]);

  return (
    <Row className="bg-white login-form">
      <Col>
        <PageTitle title={title} />
        <Form onSubmit={handleSubmit}>
          <div className="fs-0 my-3">
            {t('Please enter your email or username')}
          </div>
          <fieldset className={'form-legend-item-wrapper mb-3'}>
            <legend className="item-title fs--1">
              {t('Email or Username')}
            </legend>
            <Form.Group className="mb-0">
              <Form.Control
                placeholder={'Email address'}
                value={formData.email}
                name="username"
                onChange={handleFieldChange}
                type="username"
              />
            </Form.Group>
          </fieldset>

          <fieldset className={'form-legend-item-wrapper mb-3'}>
            <legend className="item-title fs--1">{t('Password')}</legend>
            <Form.Group className="mb-0">
              <Form.Control
                placeholder={'Password'}
                value={formData.password}
                name="password"
                onChange={handleFieldChange}
                type="password"
              />
            </Form.Group>
          </fieldset>

          <div className="text-center fw-semi-bold w-100 mb-3">
            <Link
              to={passwordResetLink}
              className="link text-decoration-underline"
            >
              {t(forgottenText)}
            </Link>
          </div>

          <Form.Group>
            <Button
              type="submit"
              color="primary"
              className="my-3 w-100"
              disabled={!formData.username || !formData.password || isPending}
            >
              {t('Continue')}
            </Button>
          </Form.Group>
        </Form>
      </Col>
    </Row>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

export default LoginForm;
