import FalconCloseButton from 'components/common/FalconCloseButton';
import { Modal } from 'react-bootstrap';
import FurnituresFixturesForm from '../forms/FurnituresFixturesForm';
import NewsForm from '../forms/NewsForm';
import PortfolioForm from '../forms/PortfolioForm';

const AddContentFormModal = ({
  show,
  content,
  showPrev,
  handleClose,
  typeCategory
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      contentClassName="border-0"
      centered
      size="lg"
    >
      <Modal.Header className="bg-modal-header light border-bottom-0">
        <FalconCloseButton noOutline onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <fieldset className={'form-legend-wrapper pb-6 px-4'}>
          <legend className="item-title fs-1 fw-semi-bold">New Content</legend>
          {content === 'f_f' && (
            <FurnituresFixturesForm
              typeCategory={typeCategory}
              close={handleClose}
              prev={showPrev}
            />
          )}
          {content === 'news' && (
            <NewsForm close={handleClose} prev={showPrev} />
          )}
          {content === 'portfolio' && (
            <PortfolioForm close={handleClose} prev={showPrev} />
          )}
        </fieldset>
      </Modal.Body>
    </Modal>
  );
};

export default AddContentFormModal;
