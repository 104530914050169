import React, { useEffect, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row, Button, Card } from 'react-bootstrap';
import ProductInfo from 'components/pages/product/ProductInfo';
// import ProductSliderComponent from 'components/pages/product/ProductSliderComponent';
import { setError } from 'redux/slicers/appsettingSlice';
import axios from 'axios';
import endpoint from 'services/endpoint';
import Loading from 'components/loading';
import { getErrorMesssage } from 'services/axiosHelpers';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Flex from 'components/common/Flex';
import EditContentModal from 'components/pages/admin/modals/ContentEditModal';
import RelatedItems from 'components/common/RelatedItems';
import StoreAccordion from 'components/pages/store/FileAndTableAccordion/StoreAcordion';
import PageTitle from 'components/common/PageTitle';
import { updateElementContentId } from 'redux/slicers/subCategoriesSlice';

const ProductContent = ({ info, setContentId }) => {
  const { translations } = useSelector(state => state.appsetting);
  const { languageCode } = useSelector(state => state.subCategories);
  const _isMounted = useRef(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingRelated, setLoadingRelated] = useState(false);
  const [relatedItems, setRelatedItems] = useState([]);
  const [product, setProduct] = useState({});
  const [documents, setDocuments] = useState([]);
  const [productContentId, setProductContentId] = useState(null);
  const isProduct = !info.content_list;

  useEffect(() => {
    _isMounted.current = true;
    setup();
    return () => {
      _isMounted.current = false;
    };
  }, [isProduct, info]);

  const setup = async () => {
    if (isProduct && info._id) {
      await getInfo(info._id);
      await getRelatedItems(info._id);
    }
  };

  /**
   * ==========Edit content start
   */
  const appsetting = useSelector(state => state.appsetting);
  const category_tree = appsetting.category_tree;
  const inline_editing = appsetting.inline_editing;
  const [parentCategoryId, setParentCategoryId] = useState('');
  const [showEditContentModal, setShowEditContentModal] = useState(false);
  const handleEditContentModalClose = () => setShowEditContentModal(false);

  /**
   * =========Edit content end
   */

  const fileCounts = 9;
  const mainInfo = useMemo(() => {
    if (!product || Object.keys(product).length === 0) {
      return;
    }
    let images = [];
    for (let index = 1; index < fileCounts; index++) {
      if (product[`image0${index}ISfile`]) {
        const image = {
          name: product[`image0${index}ISfile`]?.filename,
          src: product[`image0${index}ISfile`]?.uri
        };
        images = [...images, image];
      }
    }

    const txt =
      'Sky is committed to reaching net zero carbon by 2030, using our voice and products to inspire and support customers, companies, and communities to live a lower-carbon lifestyle. At Sky, we’re focussed on sustainable product design and delivery to drive towards net zero carbon. Since the end of 2018, all Sky products have been free of single-use plastic. We want to ensure that everything we deliver is considerate of the environment we are surrounded by and ask that when recycling of end-of-life fixtures, you adhere to manufacturer guidelines.';

    const result = {
      description:
        languageCode === 'GB'
          ? product.contentISplaintextbox
          : translations?.[productContentId]?.[languageCode]
              ?.contentISplaintextbox || product.contentISplaintextbox,
      images,
      dimensions: {
        net: product?.dimensions_net,
        packaged: product?.dimensions_packaged,
        pallet: product?.dimensions_pallet
      },
      materials: product?.materials,
      end_of_life: product[`secondary_contentISplaintextbox`]
        ? product[`secondary_contentISplaintextbox`]
        : txt
    };
    return result;
  }, [product, languageCode, productContentId]);
  // const sliderData = useMemo(() => {
  //   if (!product || product === {}) {
  //     return {};
  //   }
  //   let slides = [];
  //   for (let index = 1; index < fileCounts; index++) {
  //     if (product[`render0${index}ISfile`]) {
  //       const slide = {
  //         name: product[`render0${index}ISfile`]?.filename,
  //         image: product[`render0${index}ISfile`]?.uri
  //       };
  //       slides = [...slides, slide];
  //     }
  //   }
  //   const result = {
  //     title: 'Best Practice Photos',
  //     slides
  //   };
  //   return result;
  // }, [product]);

  const getRelatedItems = async contentId => {
    try {
      _isMounted.current && setLoadingRelated(true);
      const ep = `${endpoint.getModuleDataEndpoint(
        'bb_q4sg_content_related'
      )}?contentISbb_q4sg_contentID=${contentId}`;
      const res = await axios.get(ep);
      if (res.data && res.data.list) {
        setRelatedItems(res.data.list);
      }
    } catch (err) {
      const message = getErrorMesssage(err);
      _isMounted.current &&
        dispatch(
          setError({
            isShow: true,
            title: 'Error',
            message
          })
        );
    } finally {
      _isMounted.current && setLoadingRelated(false);
    }
  };

  const getInfo = async id => {
    try {
      _isMounted.current && setLoading(true);
      const ep = `${endpoint.getDataItemViewEndpoint('bb_q4sg_content', id)}`;
      const res = await axios.get(ep);
      const infoData = res.data;
      _isMounted.current &&
        setParentCategoryId(infoData.category01ISbb_q4sg_content_categoriesID);

      if (infoData.error) {
        const message = getErrorMesssage(infoData.error);
        _isMounted.current &&
          dispatch(
            setError({
              isShow: true,
              title: 'Error',
              message
            })
          );
      } else {
        await getDocuments(id, infoData.sub_content_tree);
        _isMounted.current && setProduct(infoData);
        setContentId(id);
        setProductContentId(id);
        dispatch(updateElementContentId(id));
      }
    } catch (err) {
      const message = getErrorMesssage(err);
      _isMounted.current &&
        dispatch(
          setError({
            isShow: true,
            title: 'Error',
            message
          })
        );
    } finally {
      _isMounted.current && setLoading(false);
    }
  };

  /**
   * ==========Edit content start ============
   */
  const modifyCategoryOnlyStructure = categories => {
    let result = [];
    if (categories && categories.length > 0) {
      categories.forEach(category => {
        const children = modifyCategoryOnlyStructure(category.sub_branches);
        const newCate = {
          name: category.name,
          id: category._id,
          children
        };
        result = [...result, newCate];
      });
    }
    return result;
  };

  const categoriesOnly = useMemo(() => {
    let result = modifyCategoryOnlyStructure(category_tree);
    return result;
  }, [category_tree]);

  const onEdit = () => {
    setShowEditContentModal(true);
  };

  const updateSuccess = () => {
    setup();
  };
  /**
   * =========Edit content end =================
   */

  const scrollToDoc = () => {
    const element = document.getElementById('doc-section');
    console.log(element);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  /**
   * =========Documents data-preparing =================
   */
  const getDocuments = async (id, subContentTree) => {
    try {
      _isMounted.current && setLoading(true);
      const ep = `${endpoint.getModuleDataEndpoint(
        'bb_q4sg_sub_content'
      )}?contentISbb_q4sg_contentID=${id}`;
      const res = await axios.get(ep);
      const infoData = res.data;

      if (infoData.error) {
        const message = getErrorMesssage(infoData.error);
        _isMounted.current &&
          dispatch(
            setError({
              isShow: true,
              title: 'Error',
              message
            })
          );
      } else {
        getSubContents(infoData.list, subContentTree);
      }
    } catch (err) {
      const message = getErrorMesssage(err);
      _isMounted.current &&
        dispatch(
          setError({
            isShow: true,
            title: 'Error',
            message
          })
        );
    } finally {
      _isMounted.current && setLoading(false);
    }
  };

  const getSubContents = async (resSubContents, subTree = []) => {
    const sortedData = {};
    subTree?.length > 0 &&
      subTree.forEach(path => {
        sortedData[path] = {
          name: path.split('/')[path.split('/').length - 1],
          data: []
        };
      });

    if (Object.keys(sortedData).length > 0) {
      resSubContents.forEach(item => {
        if (sortedData['/' + item.pathISsmallplaintextbox]) {
          sortedData['/' + item.pathISsmallplaintextbox].data.push(item);
        }
      });
    }

    const nestedData = {};
    for (const [path, { name, data }] of Object.entries(sortedData)) {
      let parentData = [];
      let parentObj = nestedData;
      const pathParts = path.split('/').filter(part => part !== '');

      for (const [index, part] of pathParts.entries()) {
        if (index < pathParts.length - 1) {
          if (!parentObj[part]) {
            parentObj[part] = { name: part, data: [] };
          }
          parentData = parentObj[part].data;
          parentObj = parentObj[part];
        } else {
          parentObj[part] = { name, data };
        }
      }
    }

    const nestedSubContentData = Object.entries(nestedData).map(
      ([key, value]) => ({ [key]: value })
    );
    setDocuments(nestedSubContentData);
  };
  // console.log(documents, 'documents');
  return (
    <>
      {loading ? (
        <Card.Body className="">
          <Loading style={{ marginTop: 50 }} msg="Loading Data..." />
        </Card.Body>
      ) : (
        <>
          {isProduct && (
            <>
              {inline_editing && (
                <Flex justifyContent="end" alignItems="center" className="">
                  <div className="button-wrapper">
                    <Button color="primary" className="w-100" onClick={onEdit}>
                      {t('Edit')}
                    </Button>
                  </div>
                </Flex>
              )}
              <Row>
                <ProductInfo info={mainInfo} toBottom={scrollToDoc} />
              </Row>
              {/* <Row className="mb-6">
                <ProductSliderComponent data={sliderData} />
              </Row> */}
              {documents.length > 0 && (
                <Row id="doc-section" className="mb-6">
                  <PageTitle title={'Documents'} />
                  <StoreAccordion data={documents} />
                </Row>
              )}

              {loadingRelated ? (
                <Card.Body className="">
                  <Loading
                    style={{ marginTop: 50 }}
                    msg="Loading Related Items..."
                  />
                </Card.Body>
              ) : (
                <>
                  {relatedItems.length > 0 && (
                    <RelatedItems relatedItems={relatedItems} />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
      {showEditContentModal && (
        <EditContentModal
          show={showEditContentModal}
          isAdd={false}
          contentId={info._id}
          categories={categoriesOnly}
          parentCategoryId={parentCategoryId}
          handleClose={handleEditContentModalClose}
          success={updateSuccess}
        />
      )}
    </>
  );
};

ProductContent.propTypes = {
  info: PropTypes.object
};

export default ProductContent;
