import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { setError } from 'redux/slicers/appsettingSlice';
import axios from 'axios';
import endpoint from 'services/endpoint';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { useTranslation } from 'react-i18next';
import { getErrorMesssage } from 'services/axiosHelpers';

const RelatedItemDeleteModal = ({ show, _id, handleClose, success }) => {
  const _isMounted = useRef(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [working, setWorking] = useState(false);
  const title = t('Delete a related item');
  const description = t('Are you sure you want to delete this related item?');
  const deleteItem = async () => {
    try {
      _isMounted.current && setWorking(true);
      const ep = endpoint.getDataItemDeleteEndpoint(
        'bb_q4sg_content_related',
        _id
      );
      await axios.delete(ep);
      _isMounted.current && success();
      _isMounted.current && handleClose();
    } catch (err) {
      const message = getErrorMesssage(err);
      _isMounted.current &&
        dispatch(
          setError({
            isShow: true,
            title: 'Error',
            message
          })
        );
    } finally {
      _isMounted.current && setWorking(false);
    }
  };

  const setup = () => {
    //
  };

  useEffect(() => {
    _isMounted.current = true;
    if (show) {
      setup();
    }
    return () => {
      _isMounted.current = false;
    };
  }, [show]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        contentClassName="border-0"
        centered
      >
        <Modal.Header className="bg-modal-header light border-bottom-0">
          <Modal.Title as="h5" className="text-white">
            {title}
          </Modal.Title>
          <FalconCloseButton noOutline variant="white" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <div>
              <FontAwesomeIcon icon="exclamation-circle" size="2xl" />
            </div>
            <div className="mt-2">{description}</div>
            {/* {data && data.name && (
              <div className="mt-2">{`(${data.name})`}</div>
            )} */}
          </div>
          <Flex alignItems="center" justifyContent="end" className="mt-4">
            <Button
              variant="outline-danger"
              size="sm"
              className="px-2 me-3 w-50"
              onClick={deleteItem}
              disabled={working}
            >
              {working ? `${t('Deleting')}...` : t('Yes, Delete')}
            </Button>
            <Button
              variant="primary"
              size="sm"
              className="px-2 w-50"
              onClick={handleClose}
            >
              {t('Go Back')}
            </Button>
          </Flex>
        </Modal.Body>
      </Modal>
    </>
  );
};

RelatedItemDeleteModal.propTypes = {
  show: PropTypes.bool,
  _id: PropTypes.string,
  handleClose: PropTypes.func,
  success: PropTypes.func
};

export default RelatedItemDeleteModal;
