import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  background_color: null,
  category_tree: [
    {
      name: 'News',
      content_list: [],
      sub_branches: [
        {
          name: 'Product Launches',
          content_list: []
        },
        {
          name: 'Store Launches',
          content_list: []
        }
      ]
    },
    {
      name: 'Carousel Images',
      content_list: []
    },
    {
      name: 'Dashboard',
      content_list: [],
      sub_branches: [
        {
          name: 'Shops',
          mage01ISfile: null,
          content_list: [],
          sub_branches: [
            {
              name: 'Furniture',
              mage01ISfile: null,
              content_list: [],
              sub_branches: []
            },
            {
              name: 'Portfolio',
              mage01ISfile: null,
              content_list: [],
              sub_branches: []
            },
            {
              name: 'Principles & Design Guidelines',
              mage01ISfile: null,
              content_list: [],
              sub_branches: []
            },
            {
              name: 'Archive',
              mage01ISfile: null,
              content_list: [],
              sub_branches: []
            }
          ]
        },
        {
          name: 'Mid Mall',
          mage01ISfile: null,
          content_list: [],
          sub_branches: [
            {
              name: 'Furniture',
              mage01ISfile: null,
              content_list: [],
              sub_branches: []
            },
            {
              name: 'Portfolio',
              mage01ISfile: null,
              content_list: [],
              sub_branches: []
            },
            {
              name: 'Principles & Design Guidelines',
              mage01ISfile: null,
              content_list: [],
              sub_branches: []
            },
            {
              name: 'Archive',
              mage01ISfile: null,
              content_list: [],
              sub_branches: []
            }
          ]
        },
        {
          name: '3rd Party & Tactical',
          mage01ISfile: null,
          content_list: [],
          sub_branches: [
            {
              name: 'Furniture',
              mage01ISfile: null,
              content_list: [],
              sub_branches: []
            },
            {
              name: 'Portfolio',
              mage01ISfile: null,
              content_list: [],
              sub_branches: []
            },
            {
              name: 'Principles & Design Guidelines',
              mage01ISfile: null,
              content_list: [],
              sub_branches: []
            },
            {
              name: 'Archive',
              mage01ISfile: null,
              content_list: [],
              sub_branches: []
            }
          ]
        },
        {
          name: 'Briefing',
          mage01ISfile: null,
          content_list: []
        },
        {
          name: 'Projects',
          mage01ISfile: null,
          content_list: []
        },
        {
          name: 'Contacts',
          mage01ISfile: null,
          content_list: []
        }
      ]
    },
    {
      name: 'Quick Links',
      content_list: []
    },
    {
      name: 'Project Sub Categories',
      content_list: [],
      sub_branches: []
    }
  ],
  default_route: '/dashboard',
  dropdowns: {
    user_type: []
  },
  env_files: [],
  favicon_16ISfile: {},
  favicon_32ISfile: {},
  favicon_192ISfile: {},
  languages: [],
  logoISfile: {},
  name: 'Skyglass',
  regions: [],
  secondary_color: null,
  splash_logoISfile: {},
  title: 'Sky Glass Portal',
  error: {
    isShow: false,
    title: '',
    message: ''
  },
  inline_editing: false,
  translations: {}
};

const appsettingSlice = createSlice({
  name: 'appsetting',
  initialState,
  reducers: {
    setInit(state, action) {
      state.background_color = action.payload.background_color;
      state.category_tree = action.payload.category_tree;
      state.default_route = action.payload.default_route;
      state.dropdowns = action.payload.dropdowns;
      state.env_files = action.payload.env_files;
      state.favicon_16ISfile = action.payload.favicon_16ISfile;
      state.favicon_32ISfile = action.payload.favicon_32ISfile;
      state.favicon_192ISfile = action.payload.favicon_192ISfile;
      state.languages = action.payload.languages;
      state.logoISfile = action.payload.logoISfile;
      state.name = action.payload.name;
      state.regions = action.payload.regions;
      state.secondary_color = action.payload.secondary_color;
      state.splash_logoISfile = action.payload.splash_logoISfile;
      state.title = action.payload.title;
      state.translations = action.payload.translations;
    },
    setError(state, action) {
      state.error.isShow = action.payload.isShow;
      state.error.title = action.payload.title;
      state.error.message = action.payload.message;
    },
    setSettings(state, action) {
      state.inline_editing = action.payload.inline_editing;
    }
  }
});

export const { setInit, setError, setSettings } = appsettingSlice.actions;

export default appsettingSlice.reducer;
