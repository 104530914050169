import React, { Fragment, useMemo, useRef, useState, useEffect } from 'react';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEarthEurope,
  faEnvelope,
  faPhone
} from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setError } from 'redux/slicers/appsettingSlice';
import axios from 'axios';
import endpoint from 'services/endpoint';
import Loading from 'components/loading';
import { Card } from 'react-bootstrap';
import { getErrorMesssage } from 'services/axiosHelpers';

const TableWrapper = ({ id }) => {
  const _isMounted = useRef(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [contentList, setContentList] = useState([]);

  const data = useMemo(() => {
    if (!contentList) {
      return [];
    }
    const results = contentList.map(c => ({
      _id: c._id,
      company: c.company,
      contact: c.contact,
      category: `${c.category}`,
      tel: `${c.telephone}`,
      email: `${c.email}`,
      web: `https://${c.web_address}`
    }));
    return results;
  }, [contentList]);

  const columns = [
    {
      accessor: 'company',
      Header: t('Company')
    },
    {
      accessor: 'contact',
      Header: t('Contact')
    },
    {
      accessor: 'category',
      Header: t('Role')
    },
    {
      accessor: 'tel',
      Header: t('Tel.'),
      Cell: rowData => {
        const { tel } = rowData.row.original;
        return (
          <>
            {tel ? (
              <OverlayTrigger
                placement={'top'}
                overlay={<Tooltip>{tel}</Tooltip>}
              >
                <FontAwesomeIcon icon={faPhone} size="xl" />
              </OverlayTrigger>
            ) : (
              <FontAwesomeIcon
                icon={faPhone}
                style={{ opacity: 0.5 }}
                size="xl"
              />
            )}
          </>
        );
      }
    },
    {
      accessor: 'email',
      Header: (
        <div className="d-flex justify-content-center">
          <span>{t('Email')}</span>
        </div>
      ),
      Cell: rowData => {
        const { email } = rowData.row.original;
        return (
          <>
            {email ? (
              <div className="d-flex justify-content-center">
                <OverlayTrigger
                  placement={'top'}
                  overlay={<Tooltip>{email}</Tooltip>}
                >
                  <a
                    href={`mailto:${email}`}
                    className="ms-1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faEnvelope} size="xl" />
                  </a>
                </OverlayTrigger>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  style={{ opacity: 0.5 }}
                  size="xl"
                />
              </div>
            )}
          </>
        );
      }
    },
    {
      accessor: 'web',
      Header: (
        <div className="d-flex justify-content-center">
          <span>{t('Web.')}</span>
        </div>
      ),
      Cell: rowData => {
        const { web } = rowData.row.original;
        const isNotLink = web.split('//')[1] === 'null';
        return (
          <>
            {isNotLink ? (
              <div className="d-flex justify-content-center">
                <FontAwesomeIcon
                  icon={faEarthEurope}
                  style={{ opacity: 0.5 }}
                  size="xl"
                />
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <a
                  href={`${web}`}
                  className="ms-1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faEarthEurope} size="xl" />
                </a>
              </div>
            )}
          </>
        );
      }
    }
  ];

  const getInfo = async _id => {
    try {
      _isMounted.current && setLoading(true);
      const ep = `${endpoint.getModuleDataEndpoint(
        'bb_q4sg_content'
      )}?category01ISbb_q4sg_content_categoriesID=${_id}`;
      const res = await axios.get(ep);
      const infoData = res.data;

      if (infoData.error) {
        const message = getErrorMesssage(infoData.error);
        _isMounted.current &&
          dispatch(
            setError({
              isShow: true,
              title: 'Error',
              message
            })
          );
      } else {
        if (infoData.list && infoData.list.length > 0) {
          _isMounted.current && setContentList(infoData.list);
        }
      }
    } catch (err) {
      const message = getErrorMesssage(err);
      _isMounted.current &&
        dispatch(
          setError({
            isShow: true,
            title: 'Error',
            message
          })
        );
    } finally {
      _isMounted.current && setLoading(false);
    }
  };

  useEffect(() => {
    _isMounted.current = true;
    if (id) {
      getInfo(id);
    }
    return () => {
      _isMounted.current = false;
    };
  }, [id]);

  return (
    <>
      {loading ? (
        <Card.Body className="">
          <Loading style={{ marginTop: 50 }} msg="Loading Data..." />
        </Card.Body>
      ) : data.length > 0 ? (
        <div className="extended-table">
          <AdvanceTableWrapper
            columns={columns}
            data={data}
            sortable={false}
            pagination
            perPage={10}
          >
            <Flex justifyContent="end" alignItems="center" className="mb-2">
              <div>
                <AdvanceTableSearchBox table />
              </div>
            </Flex>
            <AdvanceTable
              table
              headerClassName="bg-200 text-700 text-nowrap align-middle fs-0"
              rowClassName="align-middle white-space-nowrap fs-0"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs--1 mb-0'
              }}
            />
            <div className="mt-3">
              <AdvanceTablePagination table />
            </div>
          </AdvanceTableWrapper>
        </div>
      ) : (
        <div className="d-flex justify-content-center w-100 mt-3">
          <span className="f-16 fw-semi-bold">
            No contact information available at the moment
          </span>
        </div>
      )}
    </>
  );
};

TableWrapper.propTypes = {
  id: PropTypes.string
};

export default TableWrapper;
