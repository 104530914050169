import React, { useEffect, useRef, useState } from 'react';
import { Card, Image } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useTranslation } from 'react-i18next';
import { getIcon, getFileType } from 'helpers/utils';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { setError } from 'redux/slicers/appsettingSlice';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import endpoint from 'services/endpoint';
import { getErrorMesssage } from 'services/axiosHelpers';
import Loading from 'components/loading';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PlanViewercomponent = ({ file }) => {
  const [pdf, setPdf] = useState(null);
  const dispatch = useDispatch();
  const _isMounted = useRef(false);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const title = 'Store Plan';
  const { t } = useTranslation();

  const onPrint = () => {
    //
  };

  const onDownload = () => {
    //
  };

  const getPDF = async url => {
    try {
      const ep = `${endpoint.getPDF(url)}`;
      const res = await axios.get(ep);
      const infoData = res.data;
      if (infoData.base64) {
        setPdf(infoData.base64);
      }
    } catch (err) {
      const message = getErrorMesssage(err);
      _isMounted.current &&
        dispatch(
          setError({
            isShow: true,
            title: 'Error',
            message
          })
        );
    }
  };

  useEffect(() => {
    if (file && file.uri && getFileType(file.type) === 'pdf') {
      let url = file.uri;
      url = url.split('.pdf', 1) + '.pdf';
      getPDF(url);
    }
  }, [file]);

  return (
    <fieldset
      className={'form-legend-wrapper p-4 cursor-pointer border plan-viewer'}
    >
      <legend className="item-title fs-1 fw-semi-bold hover-overlay-effect">
        {t(title)}
      </legend>
      <div className="plan-image">
        {getFileType(file.type) === 'image' && (
          <TransformWrapper initialScale={1}>
            {({ zoomIn, zoomOut }) => (
              <React.Fragment>
                <div className="plan-viewer-controlls">
                  <div
                    className="plan-viewer-controll-btn cursor-pointer mb-3 hover-focus-effect"
                    onClick={() => zoomIn()}
                  >
                    <span className="icon">
                      {getIcon({
                        icon_component: 'bs/BsPlusLg',
                        icon_component_attributes: {
                          fontSize: 30,
                          marginRight: 0
                        }
                      })}
                    </span>
                  </div>
                  <div
                    className="plan-viewer-controll-btn cursor-pointer mb-3 hover-focus-effect"
                    onClick={() => zoomOut()}
                  >
                    <span className="icon">
                      {getIcon({
                        icon_component: 'bs/BsDashLg',
                        icon_component_attributes: {
                          fontSize: 30,
                          marginRight: 0
                        }
                      })}
                    </span>
                  </div>
                  <div
                    className="plan-viewer-controll-btn cursor-pointer mb-3 hover-focus-effect"
                    onClick={onPrint}
                  >
                    <span className="icon">
                      {getIcon({
                        icon_component: 'bs/BsPrinter',
                        icon_component_attributes: {
                          fontSize: 30,
                          marginRight: 0
                        }
                      })}
                    </span>
                  </div>
                  <div
                    className="plan-viewer-controll-btn cursor-pointer hover-focus-effect"
                    onClick={onDownload}
                  >
                    <span className="icon">
                      {getIcon({
                        icon_component: 'bs/BsDownload',
                        icon_component_attributes: {
                          fontSize: 30,
                          marginRight: 0
                        }
                      })}
                    </span>
                  </div>
                </div>
                <TransformComponent>
                  <Flex justifyContent="center" alignItems={'center'}>
                    <Image fluid src={file.uri} alt="" />
                  </Flex>
                </TransformComponent>
              </React.Fragment>
            )}
          </TransformWrapper>
        )}
        {getFileType(file.type) === 'pdf' && (
          <>
            {!pdf ? (
              <Card.Body className="">
                <Loading style={{ marginTop: 50 }} msg="Loading Data..." />
              </Card.Body>
            ) : (
              <div style={{ height: '650px' }}>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.5.141/build/pdf.worker.js">
                  <Viewer
                    fileUrl={`${pdf}`}
                    plugins={[defaultLayoutPluginInstance]}
                  />
                </Worker>
              </div>
            )}
          </>
        )}
      </div>
    </fieldset>
  );
};

PlanViewercomponent.propTypes = {
  file: PropTypes.object
};

export default PlanViewercomponent;
