import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    subContents:[],
    languageCode:'GB',
    elementContentId:'',
    elementContentName:''
};

const subCategoriesSlice = createSlice({
  name: 'subCategories',
  initialState,
  reducers: {
    dispatchSubContents(state, {payload}) {
      state.subContents = payload;
    },
    updateLanguage(state, {payload}) {
      state.languageCode = payload;
    },
    updateElementContentId(state, {payload}) {
      state.elementContentId = payload;
    },
    updateElementContentName(state, {payload}) {
      state.elementContentName = payload;
    },
  }
});

export const { dispatchSubContents, updateLanguage, updateElementContentId, updateElementContentName } = subCategoriesSlice.actions;

export default subCategoriesSlice.reducer;
