import React, { Fragment, useEffect, useRef, useState } from 'react';
import PageTitle from 'components/common/PageTitle';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setError } from 'redux/slicers/appsettingSlice';
import axios from 'axios';
import endpoint from 'services/endpoint';
import { parseFormString } from 'helpers/utils';
import { Card, Image, Row, Col, Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import FileFormField from 'components/common/file-upload/FileFormField';
import Loading from 'components/loading';
import { getErrorMesssage } from 'services/axiosHelpers';
import { replaceDashesWithSpaces } from 'helpers/utils';

const BriefingForm = () => {
  const _isMounted = useRef(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { category: cat } = useParams();
  const category = cat && replaceDashesWithSpaces(cat);

  const title = category;
  const category_tree = useSelector(state => state.appsetting.category_tree);
  const sub_branches =
    category_tree.find(c => c.name === 'Dashboard').sub_branches || [];
  const itemsCate = sub_branches.find(s => s.name === 'Briefing');
  const inCateSubCates = itemsCate ? itemsCate.sub_branches || [] : [];
  const subCateInfo = inCateSubCates.find(c => c.name === category) || {};
  const initDate = new Date();

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [formTitle, setFormTitle] = useState('');
  const [fields, setFields] = useState([]);
  const [image, setImage] = useState(null);

  const [formData, setFormData] = useState({});

  const isHide = title === 'Briefing & New Project Process';

  const headerStr = 'FORM:';

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleCheckboxChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked
    });
  };

  const uploadFile = (file, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: file
    });
  };

  const makePostData = async () => {
    let data = {};
    for (let index = 0; index < fields.length; index++) {
      const field = fields[index];
      let value = formData[field.name];
      if (field.type === 'Date' && value) {
        value = moment(value).format('MM-DD-YYYY');
      }
      data = {
        ...data,
        [field.name]: value
      };
    }
    return data;
  };

  const onSave = async () => {
    const bodyData = await makePostData();
    const data = {
      name: formTitle,
      dataISsmallplaintextbox: JSON.stringify(bodyData)
    };
    _isMounted.current && setSaving(false);

    try {
      _isMounted.current && setSaving(true);
      const ep = endpoint.getDataAddEndpoint('bb_q4sg_forms');
      await axios.post(ep, data);
      _isMounted.current && setSaving(false);
      _isMounted.current &&
        dispatch(
          setError({
            isShow: true,
            title: t('Success'),
            message: t('Submitted Successfully! Thank you')
          })
        );
    } catch (err) {
      const message = getErrorMesssage(err);
      _isMounted.current &&
        dispatch(
          setError({
            isShow: true,
            title: 'Error',
            message
          })
        );
    } finally {
      _isMounted.current && setSaving(false);
    }
  };

  const setUpInitForm = formItems => {
    let initForm = {};
    formItems.forEach(field => {
      let value = '';
      if (field.type === 'Text') {
        value = '';
      } else if (field.type === 'Textblock') {
        value = '';
      } else if (field.type === 'Checkbox') {
        value = false;
      } else if (field.type === 'Date') {
        value = '';
        if (field.name === 'date_of_request') {
          value = initDate;
        }
      } else if (field.type === 'File') {
        value = null;
      }

      initForm = {
        ...initForm,
        [field.name]: value
      };
    });
    //console.log('========', initForm);
    setFormData(initForm);
  };

  const getSubCateInfo = async _id => {
    try {
      _isMounted.current && setLoading(true);
      const ep = `${endpoint.getDataItemViewEndpoint(
        'bb_q4sg_content_categories',
        _id
      )}`;
      const res = await axios.get(ep);
      const infoData = res.data;

      if (infoData.error) {
        const message = getErrorMesssage(infoData.error);
        _isMounted.current &&
          dispatch(
            setError({
              isShow: true,
              title: 'Error',
              message
            })
          );
      } else {
        const formItems = parseFormString(
          infoData.summaryISsmallplaintextbox,
          headerStr
        );
        _isMounted.current && setImage(infoData.image02ISfile);
        _isMounted.current && setFields(formItems);
        _isMounted.current && setUpInitForm(formItems);
        _isMounted.current && setFormTitle(infoData.name);
      }
    } catch (err) {
      const message = getErrorMesssage(err);
      _isMounted.current &&
        dispatch(
          setError({
            isShow: true,
            title: 'Error',
            message
          })
        );
    } finally {
      _isMounted.current && setLoading(false);
    }
  };

  useEffect(() => {
    _isMounted.current = true;
    if (subCateInfo._id) {
      getSubCateInfo(subCateInfo._id);
    }
    return () => {
      _isMounted.current = false;
    };
  }, [subCateInfo]);

  return (
    <div>
      <PageTitle title={title} />
      <div className="page-content pt-6 mb-6">
        {loading && (
          <Card.Body className="">
            <Loading style={{ marginTop: 50 }} msg="Loading Data..." />
          </Card.Body>
        )}
        {!loading && (
          <div className="briefing-form-wrapper text-center">
            {image && image.uri && (
              <Image fluid src={image.uri} className="briefing-form-img mb-4" />
            )}
            {!isHide && (
              <fieldset className={'form-legend-wrapper pb-6 px-4'}>
                <legend className="item-title fs-1 fw-semi-bold ms-0">
                  {t(formTitle)}
                </legend>
                <Row className="text-start">
                  {fields.map((field, index) => (
                    <Fragment key={index}>
                      {field.type === 'Text' && (
                        <Col lg={field.width}>
                          <fieldset className={'form-legend-item-wrapper mb-4'}>
                            <legend className="item-title fs-1 fw-semi-bold">
                              {t(field.label)}
                            </legend>
                            <Form.Group className="mb-0">
                              <Form.Control
                                required
                                placeholder={''}
                                value={formData[field.name]}
                                name={field.name}
                                onChange={handleFieldChange}
                                type="text"
                              />
                            </Form.Group>
                          </fieldset>
                        </Col>
                      )}
                      {field.type === 'Textblock' && (
                        <Col lg={field.width}>
                          <fieldset className={'form-legend-item-wrapper mb-4'}>
                            <legend className="item-title fs-1 fw-semi-bold">
                              {t(field.label)}
                            </legend>
                            <Form.Control
                              required
                              placeholder={''}
                              value={formData[field.name]}
                              name={field.name}
                              onChange={handleFieldChange}
                              as="textarea"
                            />
                          </fieldset>
                        </Col>
                      )}
                      {field.type === 'Checkbox' && (
                        <Col lg={field.width}>
                          <Form.Check
                            type="checkbox"
                            label={t(field.label)}
                            defaultChecked={formData[field.name]}
                            name={field.name}
                            onChange={handleCheckboxChange}
                          />
                        </Col>
                      )}
                      {field.type === 'Date' && (
                        <Col lg={field.width}>
                          <fieldset className={'form-legend-item-wrapper mb-4'}>
                            <legend className="item-title fs-1 fw-semi-bold">
                              {t(field.label)}
                            </legend>
                            <DatePicker
                              selected={formData[field.name] ?? ''}
                              onChange={date => {
                                setFormData({
                                  ...formData,
                                  [field.name]: date
                                });
                              }}
                              placeholderText="MM-DD-YYYY"
                              dateFormat="MM-dd-yyyy"
                              className="form-control"
                            />
                          </fieldset>
                        </Col>
                      )}
                      {field.type === 'File' && (
                        <Col lg={field.width}>
                          <fieldset
                            className={'form-legend-item-wrapper mb-4 p-4'}
                          >
                            <legend className="item-title fs-1 fw-semi-bold">
                              {t(field.label)}
                            </legend>
                            <FileFormField
                              uploadFile={f => uploadFile(f, field.name)}
                            />
                          </fieldset>
                        </Col>
                      )}
                    </Fragment>
                  ))}
                </Row>
                <Row>
                  <Button
                    variant="primary"
                    size="sm"
                    className="px-2"
                    onClick={onSave}
                    disabled={saving}
                  >
                    {saving ? `${t('Submitting')}...` : t('Submit')}
                  </Button>
                </Row>
              </fieldset>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BriefingForm;
