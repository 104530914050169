import React, { useEffect } from 'react';
import PageTitle from 'components/common/PageTitle';
import NavItemComponent from 'components/common/NavItemComponent';
import { useDispatch, useSelector } from 'react-redux';
import { getURLFromName } from 'helpers/utils';
import { Col, Row } from 'react-bootstrap';
import placeholderImage from 'assets/img/placeholder.png';
import { getErrorMesssage } from 'services/axiosHelpers';
import Logo from 'assets/img/Logo-Sky.png';
import useMobileScreenSize from 'hooks/useMobileScreenSize';
import { useTranslation } from 'react-i18next';

const Landing = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const isMobile = useMobileScreenSize();

  const category_tree = useSelector(state => state.appsetting.category_tree);
  const inItems = category_tree.find(c => c.name === 'Dashboard').sub_branches;
  const items = inItems.map(item => {
    return {
      ...item,
      title: item.name,
      url: getURLFromName(item.name),
      icon: item.image01ISfile ?? placeholderImage
    };
  });

  const title = (
    <div className="flex align-items-center flex-wrap">
      <span>{t('Welcome to the')}</span>
      <img
        src={Logo}
        alt="Sky Toolkit Logo"
        style={{
          marginLeft: '16px',
          width: isMobile ? '180px' : '250px',
          height: isMobile ? '47.7757px' : '57.33px'
        }}
      />
    </div>
  );
  return (
    <div>
      <PageTitle>{title}</PageTitle>
      <div className="page-content pt-6 mb-6">
        <Row>
          {items.map((item, index) => (
            <Col key={index} md="4" className="mb-4">
              <NavItemComponent {...item} className="mb-4 h-100" />
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default Landing;
