import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import PageTitle from 'components/common/PageTitle';
import Loading from 'components/loading';
import { Fragment, useState } from 'react';
import { Button, Card, Nav, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AddContentFormModal from './modal/content/AddContentFormModal';
import MyAccount from '../admin/MyAccount';
import MyContent from './MyContent';
import AddContentCategoryModal from './modal/categories/AddContentCategoryModal';

const Account = () => {
  const [formData, setFormData] = useState({
    category01ISbb_q4sg_content_categoriesID: ''
  });
  const [loading, setLoading] = useState(false);
  const [kind, setKind] = useState('my-account');
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [showContentFormModal, setShowContentFormModal] = useState(false);
  const [contentFormModal, setContentFormModal] = useState();

  const handleOpenCategoryModal = () => setShowCategoryModal(true);
  const handleCloseCategoryModal = () => setShowCategoryModal(false);

  const handleOpenContentFormModal = () => setShowContentFormModal(true);
  const handleCloseContentFormModal = () => setShowContentFormModal(false);

  const { t } = useTranslation();

  const kinds = [
    {
      key: 'my-account',
      name: t('MyAccount')
    },
    {
      key: 'my-content',
      name: t('MyContent')
    }
  ];

  return (
    <>
      <PageTitle title={t('MyAccount')} />
      <div>
        {loading ? (
          <Card.Body className="">
            <Loading style={{ marginTop: 50 }} msg="Loading Data..." />
          </Card.Body>
        ) : (
          <div className="page-content mb-6">
            <Tab.Container
              activeKey={kind}
              onSelect={k => setKind(k)}
              id="countries-tab"
              transition={false}
            >
              <Flex justifyContent="between" alignItems="center" className="">
                <Nav variant="tabs" className="flex-row mb-3">
                  {kinds.map((c, index) => (
                    <Fragment key={index}>
                      <Nav.Item>
                        <Nav.Link
                          eventKey={c.key}
                          className="fw-semi-bold fs-1"
                        >
                          {t(c.name)}
                        </Nav.Link>
                      </Nav.Item>
                    </Fragment>
                  ))}
                </Nav>

                {kind === 'my-content' && (
                  <Flex
                    justifyContent="between"
                    alignItems="center"
                    className=""
                  >
                    <div className="button-wrapper">
                      <Button
                        color="primary"
                        className="w-100"
                        onClick={handleOpenCategoryModal}
                      >
                        <span>
                          <FontAwesomeIcon icon={'add'} className="me-2" />
                        </span>
                        <span>{t('Add Content')}</span>
                      </Button>
                    </div>
                  </Flex>
                )}
              </Flex>
              <Tab.Content>
                {kind === 'my-account' && <MyAccount />}
                {kind === 'my-content' && <MyContent />}
              </Tab.Content>
            </Tab.Container>
          </div>
        )}
      </div>
      {showCategoryModal && (
        <AddContentCategoryModal
          show={showCategoryModal}
          openContentForm={handleOpenContentFormModal}
          handleClose={handleCloseCategoryModal}
          setFormData={setFormData}
          setContentModal={setContentFormModal}
          formData={formData}
        />
      )}
      {showContentFormModal && (
        <AddContentFormModal
          show={showContentFormModal}
          content={contentFormModal}
          showPrev={handleOpenCategoryModal}
          handleClose={handleCloseContentFormModal}
          typeCategory={formData.category01ISbb_q4sg_content_categoriesID}
        />
      )}
    </>
  );
};

export default Account;
