import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
  Modal,
  Row,
  Col,
  Card,
  Tab,
  Tabs
} from 'react-bootstrap';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { setError } from 'redux/slicers/appsettingSlice';
import axios from 'axios';
import endpoint from 'services/endpoint';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ImageUploadField from 'components/common/file-upload/ImageUploadField';
import DocumentUploadField from 'components/common/file-upload/DocumentUploadField';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import Loading from 'components/loading';
import { getIcon } from 'helpers/utils';
import Flex from 'components/common/Flex';
import SubContentDeleteModal from './SubContentDeleteModal';
import SubContentAddModal from './SubContentAddModal';
import RelatedItemDeleteModal from './RelatedItemDeleteModal';
import RelatedItemAddModal from './RelatedItemAddModal';
import Treeview from 'components/common/tree-view/TreeviewSelectCatgory';
import { toast } from 'react-toastify';
import { getErrorMesssage } from 'services/axiosHelpers';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const ContentEditModal = ({
  show,
  isAdd,
  categories,
  contentId,
  parentCategoryId = '',
  handleClose,
  success
}) => {
  const _isMounted = useRef(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const category_tree = useSelector(state => state.appsetting.category_tree);
  const project_sub_categories = category_tree.find(
    c => c.name === 'Project Sub Categories'
  );
  const sub_branches = project_sub_categories
    ? project_sub_categories.sub_branches || []
    : [];
  const [loading, setLoading] = useState(false);
  const [loadingSub, setLoadingSub] = useState(false);
  const [loadingRelated, setLoadingRelated] = useState(false);
  const [saving, setSaving] = useState(false);
  const title = isAdd ? t('New Content') : t('Edit Content');
  const btnCaption = t('Save');

  const [subContents, setSubContents] = useState([]);
  const [relatedItems, setRelatedItems] = useState([]);
  const [selectedSubContent, setSelectedSubContent] = useState({});
  const [selectedRelatedItem, setSelectedRelatedItem] = useState({});
  const [showDeleteSubContentModal, setShowDeleteSubContentModal] =
    useState(false);
  const [showDeleteRelatedItemModal, setShowDeleteRelatedItemModal] =
    useState(false);
  const [showAddSubContentModal, setShowAddSubContentModal] = useState(false);
  const [showAddRelatedItemModal, setShowAddRelatedItemModal] = useState(false);
  const handleDeleteSubContentModalClose = () =>
    setShowDeleteSubContentModal(false);
  const handleAddSubContentModalClose = () => setShowAddSubContentModal(false);

  const handleDeleteRelatedItemModalClose = () =>
    setShowDeleteRelatedItemModal(false);
  const handleAddRelatedItemModalClose = () =>
    setShowAddRelatedItemModal(false);

  const counts = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  const [selectedCategories, setSeletectedCategories] = useState([]);

  const mn = 'bb_q4sg_content';
  //const fileCouns = 9;
  //const submn = 'bb_q4sg_sub_content';

  const [formData, setFormData] = useState({
    name: '',
    category01ISbb_q4sg_content_categoriesID: '',
    summaryISsmallplaintextbox: '',
    contentISplaintextbox: '',
    video_url: '',
    urlISplaintextbox: '',
    contact: '',
    category: '',
    web_address: '',
    date_start: '',
    status: '',
    dimensions_net: '',
    orderNUM: ''
  });

  const [summary, setSummary] = useState('');
  const [contentPlain, setContentPlain] = useState('');

  const getSubContentCategoryName = id => {
    let result = '';
    const sutContentCategory = sub_branches.find(s => s._id === id);
    if (sutContentCategory) {
      result = sutContentCategory.name;
    }
    return result;
  };

  const subContentTableData = useMemo(() => {
    if (!subContents || subContents.length < 1) {
      return [];
    }
    let results = subContents.map(c => ({
      _id: c._id,
      title: c.name,
      category: getSubContentCategoryName(
        c.sub_categoryISbb_q4sg_content_categoriesID
      ),
      pdf: c?.document01ISfile?.uri,
      dwg: c?.document02ISfile?.uri,
      cad: c?.document03ISfile?.uri
    }));
    return results;
  }, [subContents]);

  const relatedItemsTableData = useMemo(() => {
    if (!relatedItems || relatedItems.length < 1) {
      return [];
    }
    // console.log('-------relatedItems', relatedItems);
    let results = relatedItems.map(c => ({
      _id: c._id,
      name: c.related_contentISbb_q4sg_contentID_display
    }));
    return results;
  }, [relatedItems]);

  const onFile = (url, type) => {
    if (!url) {
      return;
    }
    console.log(type);
    window.open(url, '_blank');
  };

  const subContentColumns = [
    {
      accessor: 'title',
      Header: t('Title')
    },
    {
      accessor: 'category',
      Header: t('Category')
    },
    {
      accessor: 'pdf',
      Header: t('PDF'),
      Cell: rowData => {
        const { pdf } = rowData.row.original;
        return (
          <>
            <div
              style={{ opacity: pdf ? 1 : 0.5 }}
              className="cursor-pointer text-center"
              onClick={() => onFile(pdf, 'pdf')}
            >
              {getIcon({
                icon_component: 'fi/FiFileText',
                icon_component_attributes: { fontSize: 30, marginRight: 0 }
              })}
            </div>
          </>
        );
      }
    },
    {
      accessor: 'dwg',
      Header: t('DWG'),
      Cell: rowData => {
        const { dwg } = rowData.row.original;
        return (
          <div
            style={{ opacity: dwg ? 1 : 0.5 }}
            className="cursor-pointer text-center"
            onClick={() => onFile(dwg, 'dwg')}
          >
            {getIcon({
              icon_component: 'fi/FiFileText',
              icon_component_attributes: { fontSize: 30, marginRight: 0 }
            })}
          </div>
        );
      }
    },
    {
      accessor: 'action',
      Header: t(''),
      Cell: rowData => {
        const { _id } = rowData.row.original;
        return (
          <>
            <div
              className="cursor-pointer text-center"
              onClick={() => onDeleteSubContent(_id)}
            >
              {getIcon({
                icon_component: 'md/MdDelete',
                icon_component_attributes: { fontSize: 20, marginRight: 0 }
              })}
            </div>
          </>
        );
      }
    }
  ];

  const relatedItemsColumns = [
    {
      accessor: 'name',
      Header: t('Title')
    },
    {
      accessor: 'action',
      Header: t('Action'),
      Cell: rowData => {
        const { _id } = rowData.row.original;
        return (
          <>
            <div
              className="cursor-pointer text-center"
              onClick={() => onDeleteRelatedItem(_id)}
            >
              {getIcon({
                icon_component: 'md/MdDelete',
                icon_component_attributes: { fontSize: 20, marginRight: 0 }
              })}
            </div>
          </>
        );
      }
    }
  ];

  const setCategory = items => {
    if (items && items.length > 0) {
      setSeletectedCategories(items);
      setFormData({
        ...formData,
        category01ISbb_q4sg_content_categoriesID: items[0]
      });
    } else {
      setSeletectedCategories([]);
      setFormData({
        ...formData,
        category01ISbb_q4sg_content_categoriesID: ''
      });
    }
  };

  const onSelectSubContent = _id => {
    const sub = subContents.find(s => s._id === _id);
    if (sub) {
      setSelectedSubContent(sub);
    }
  };

  const onSelectRelatedItem = _id => {
    const r = relatedItems.find(s => s._id === _id);
    if (r) {
      setSelectedRelatedItem(r);
    }
  };

  const onDeleteSubContent = async _id => {
    onSelectSubContent(_id);
    console.log(_id);
    setShowDeleteSubContentModal(true);
  };

  const onDeleteRelatedItem = async _id => {
    onSelectRelatedItem(_id);
    console.log(_id);
    setShowDeleteRelatedItemModal(true);
  };

  const onAddSubContent = async () => {
    setShowAddSubContentModal(true);
  };

  const onAddRelatedItem = async () => {
    setShowAddRelatedItemModal(true);
  };

  const updateSubContentSuccess = async () => {
    getSubContents(contentId, sub_branches);
  };

  const updateRelatedItemSuccess = async () => {
    getRelatedItems(contentId);
  };

  const onChangeSummary = data => {
    const htmlData = data.toString('html');
    setSummary(data);
    const newFormData = {
      ...formData,
      summaryISsmallplaintextbox: htmlData
    };

    setFormData(newFormData);
  };

  const onChangeContentPlain = data => {
    const htmlData = data.toString('html');
    setContentPlain(data);
    const newFormData = {
      ...formData,
      contentISplaintextbox: htmlData
    };

    setFormData(newFormData);
  };

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const uploadFile = (file, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: file
    });
  };

  const showToastMessage = (type, message) => {
    if (type === 'success') {
      toast.success(message, {
        theme: 'colored'
      });
    } else {
      toast.error(message, {
        theme: 'colored'
      });
    }
  };

  const save = async formData => {
    if (!formData.name) {
      showToastMessage('error', 'Name is required');
      return;
    }
    if (!formData.category01ISbb_q4sg_content_categoriesID) {
      showToastMessage('error', 'Category is required');
      return;
    }
    try {
      _isMounted.current && setSaving(true);
      let data = formData;
      data = {
        name: formData.name,
        category01ISbb_q4sg_content_categoriesID:
          formData.category01ISbb_q4sg_content_categoriesID,
        summaryISsmallplaintextbox: formData.summaryISsmallplaintextbox,
        contentISplaintextbox: formData.contentISplaintextbox,
        video_url: formData.video_url,
        urlISplaintextbox: formData.urlISplaintextbox,
        contact: formData.contact,
        category: formData.category,
        web_address: formData.web_address,
        date_start: formData.date_start,
        status: formData.status,
        dimensions_net: formData.dimensions_net,
        orderNUM: formData.orderNUM
      };

      const ep = endpoint.getDataAddEndpoint(mn);
      // console.log('----save---ep, data:', ep, data);
      const res = await axios.post(ep, data);
      // console.log('----save---res:', res);

      if (res.data && res.data._id) {
        await update(formData, res.data._id);
      }
    } catch (err) {
      const message = getErrorMesssage(err);
      _isMounted.current &&
        dispatch(
          setError({
            isShow: true,
            title: 'Error',
            message
          })
        );
    } finally {
      _isMounted.current && setSaving(false);
    }
  };

  const update = async (formData, contentId) => {
    if (!formData.name) {
      showToastMessage('error', 'Name is required');
      return;
    }
    if (!formData.category01ISbb_q4sg_content_categoriesID) {
      showToastMessage('error', 'Category is required');
      return;
    }
    try {
      _isMounted.current && setSaving(true);
      let data = formData;
      console.log('----data---', data);

      const ep = endpoint.getDataItemEditEndpoint(mn, contentId);
      // console.log('----update---ep, data:', ep, data);
      const res = await axios.patch(ep, data);
      // console.log('----update---res:', res);

      if (res.data) {
        _isMounted.current && success();
        _isMounted.current && handleClose();
      }
    } catch (err) {
      const message = getErrorMesssage(err);
      _isMounted.current &&
        dispatch(
          setError({
            isShow: true,
            title: 'Error',
            message
          })
        );
    } finally {
      _isMounted.current && setSaving(false);
    }
  };

  const removeFile = async file => {
    if (isAdd || !contentId) {
      return;
    }
    try {
      const ep = `${endpoint.getDataItemEditEndpoint(
        mn,
        contentId
      )}/files/${file}`;
      await axios.delete(ep);
    } catch (err) {
      const message = getErrorMesssage(err);
      _isMounted.current &&
        dispatch(
          setError({
            isShow: true,
            title: 'Error',
            message
          })
        );
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (isAdd) {
      await save(formData);
    } else {
      await update(formData, contentId);
    }
  };

  const onViewDoc = (file, fieldName) => {
    console.log(file, fieldName);
  };

  const getSubContents = async (id, sub_branches) => {
    let tempSubContents = [];
    for (let index = 0; index < sub_branches.length; index++) {
      const sub_branch = sub_branches[index];
      const resSubContents = await getSubContent(id, sub_branch._id);
      // console.log('--------subContent', id, sub_branch._id, resSubContents);
      tempSubContents = [...tempSubContents, ...resSubContents];
    }
    _isMounted.current && setSubContents(tempSubContents);
  };

  const getSubContent = async (contentId, catId) => {
    try {
      _isMounted.current && setLoadingSub(true);
      const ep = `${endpoint.getModuleDataEndpoint(
        'bb_q4sg_sub_content'
      )}?contentISbb_q4sg_contentID=${contentId}&sub_categoryISbb_q4sg_content_categoriesID=${catId}`;
      const res = await axios.get(ep);
      return res.data.list;
    } catch (err) {
      const message = getErrorMesssage(err);
      _isMounted.current &&
        dispatch(
          setError({
            isShow: true,
            title: 'Error',
            message
          })
        );
      return [];
    } finally {
      _isMounted.current && setLoadingSub(false);
    }
  };

  const getRelatedItems = async contentId => {
    try {
      _isMounted.current && setLoadingRelated(true);
      const ep = `${endpoint.getModuleDataEndpoint(
        'bb_q4sg_content_related'
      )}?contentISbb_q4sg_contentID=${contentId}`;
      const res = await axios.get(ep);
      if (res.data && res.data.list) {
        setRelatedItems(res.data.list);
      }
    } catch (err) {
      const message = getErrorMesssage(err);
      _isMounted.current &&
        dispatch(
          setError({
            isShow: true,
            title: 'Error',
            message
          })
        );
    } finally {
      _isMounted.current && setLoadingRelated(false);
    }
  };

  const getContent = async _id => {
    try {
      _isMounted.current && setLoading(true);
      const ep = `${endpoint.getDataItemViewEndpoint(mn, _id)}`;
      const res = await axios.get(ep);
      const infoData = res.data;

      console.log('-----Content----------', infoData);

      if (infoData.error) {
        const message = getErrorMesssage(infoData.error);
        _isMounted.current &&
          dispatch(
            setError({
              isShow: true,
              title: 'Error',
              message
            })
          );
      } else {
        _isMounted.current && setupForm(infoData);
        _isMounted.current && getSubContents(contentId, sub_branches);
        _isMounted.current && getRelatedItems(contentId);
      }
    } catch (err) {
      const message = getErrorMesssage(err);
      _isMounted.current &&
        dispatch(
          setError({
            isShow: true,
            title: 'Error',
            message
          })
        );
    } finally {
      _isMounted.current && setLoading(false);
    }
  };

  const setupForm = infoData => {
    let initFormData = {
      name: infoData.name ?? '',
      category01ISbb_q4sg_content_categoriesID:
        infoData.category01ISbb_q4sg_content_categoriesID ?? '',
      summaryISsmallplaintextbox: infoData.summaryISsmallplaintextbox ?? '',
      contentISplaintextbox: infoData.contentISplaintextbox ?? '',
      image01ISfile: infoData.image01ISfile ?? null,
      image02ISfile: infoData.image02ISfile ?? null,
      image03ISfile: infoData.image03ISfile ?? null,
      image04ISfile: infoData.image04ISfile ?? null,
      image05ISfile: infoData.image05ISfile ?? null,
      image06ISfile: infoData.image06ISfile ?? null,
      image07ISfile: infoData.image07ISfile ?? null,
      image08ISfile: infoData.image08ISfile ?? null,
      image09ISfile: infoData.image09ISfile ?? null,
      document01ISfile: infoData.document01ISfile ?? null,
      document02ISfile: infoData.document02ISfile ?? null,
      document03ISfile: infoData.document03ISfile ?? null,
      document04ISfile: infoData.document04ISfile ?? null,
      document05ISfile: infoData.document05ISfile ?? null,
      document06ISfile: infoData.document06ISfile ?? null,
      document07ISfile: infoData.document07ISfile ?? null,
      document08ISfile: infoData.document08ISfile ?? null,
      document09ISfile: infoData.document09ISfile ?? null,
      render01ISfile: infoData.render01ISfile ?? null,
      render02ISfile: infoData.render02ISfile ?? null,
      render03ISfile: infoData.render03ISfile ?? null,
      render04ISfile: infoData.render04ISfile ?? null,
      render05ISfile: infoData.render05ISfile ?? null,
      render06ISfile: infoData.render06ISfile ?? null,
      render07ISfile: infoData.render07ISfile ?? null,
      render08ISfile: infoData.render08ISfile ?? null,
      render09ISfile: infoData.render09ISfile ?? null,
      video_url: infoData.video_url ?? '',
      urlISplaintextbox: infoData.urlISplaintextbox ?? '',
      contact: infoData.contact ?? '',
      category: infoData.category ?? '',
      web_address: infoData.web_address ?? '',
      date_start: infoData.date_start ?? '',
      status: infoData.status ?? '',
      dimensions_net: infoData.dimensions_net ?? '',
      orderNUM: infoData.orderNUM ?? ''
    };
    setFormData(initFormData);
    if (infoData.summaryISsmallplaintextbox) {
      setSummary(infoData.summaryISsmallplaintextbox);
    }
    if (infoData.contentISplaintextbox) {
      setContentPlain(infoData.contentISplaintextbox);
    }
    if (infoData.category01ISbb_q4sg_content_categoriesID) {
      setSeletectedCategories([
        infoData.category01ISbb_q4sg_content_categoriesID
      ]);
    }
  };

  const setup = () => {
    if (isAdd) {
      let initData = {};
      if (parentCategoryId) {
        initData = {
          ...initData,
          category01ISbb_q4sg_content_categoriesID: parentCategoryId
        };
      }
      setupForm(initData);
    } else {
      getContent(contentId);
    }
  };

  useEffect(() => {
    _isMounted.current = true;
    if (show) {
      setup();
    }
    return () => {
      _isMounted.current = false;
    };
  }, [show]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        contentClassName="border-0"
        centered
        size="lg"
      >
        <Modal.Header className="bg-modal-header light border-bottom-0">
          <FalconCloseButton noOutline onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Card.Body className="">
              <Loading style={{ marginTop: 50 }} msg="Loading Data..." />
            </Card.Body>
          ) : (
            <fieldset className={'form-legend-wrapper pb-6 px-4'}>
              <legend className="item-title fs-1 fw-semi-bold">
                {t(title)}
              </legend>
              <Form onSubmit={handleSubmit} className="pt-4">
                <Row className="mb-3">
                  <Col>
                    <fieldset className={'form-legend-item-wrapper'}>
                      <legend className="item-title fs-0 fw-semi-bold">
                        {t('Name')}
                      </legend>
                      <Form.Group className="mb-0">
                        <Form.Control
                          placeholder={''}
                          value={formData.name}
                          name="name"
                          onChange={handleChange}
                          type="text"
                        />
                      </Form.Group>
                    </fieldset>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <fieldset className={'form-legend-item-wrapper mb-4 px-4'}>
                      <legend className="item-title fs-0 fw-semi-bold">
                        {t('Category')}
                      </legend>
                      <Treeview
                        data={categories}
                        selection
                        selectedItems={selectedCategories}
                        expanded={selectedCategories}
                        setSelectedItems={setCategory}
                      />
                    </fieldset>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <fieldset className={'form-legend-item-wrapper'}>
                      <legend className="item-title fs-0 fw-semi-bold">
                        {t('Summary')}
                      </legend>
                      <Form.Group className="mb-0">
                        <ReactQuill
                          theme="snow"
                          value={summary}
                          onChange={onChangeSummary}
                        />
                      </Form.Group>
                    </fieldset>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <fieldset className={'form-legend-item-wrapper'}>
                      <legend className="item-title fs-0 fw-semi-bold">
                        {t('Content')}
                      </legend>
                      <Form.Group className="mb-0">
                        <ReactQuill
                          theme="snow"
                          value={contentPlain}
                          onChange={onChangeContentPlain}
                        />
                      </Form.Group>
                    </fieldset>
                  </Col>
                </Row>
                <div>
                  <Tabs
                    defaultActiveKey="images"
                    id="uncontrolled-tab-example"
                    transition={false}
                  >
                    <Tab eventKey="images" title="Images" className="p-3">
                      <Row className="mb-3">
                        {counts.map((num, index) => (
                          <Col lg={4} key={index} className="mb-2">
                            <fieldset
                              className={'form-legend-item-wrapper p-2'}
                            >
                              <legend className="item-title fs-0 ms-2 fw-semi-bold">
                                {`${t('Image')} ${num}`}
                              </legend>
                              <ImageUploadField
                                inputImage={formData[`image0${num}ISfile`]}
                                uploadFile={f =>
                                  uploadFile(f, `image0${num}ISfile`)
                                }
                                removeFile={() =>
                                  removeFile(`image0${num}ISfile`)
                                }
                              />
                            </fieldset>
                          </Col>
                        ))}
                      </Row>
                    </Tab>
                    <Tab eventKey="documents" title="Documents" className="p-3">
                      <Row className="mb-3">
                        {counts.map((num, index) => (
                          <Col lg={4} key={index} className="mb-2">
                            <fieldset
                              className={'form-legend-item-wrapper p-2'}
                            >
                              <legend className="item-title fs-0 ms-2 fw-semi-bold">
                                {`${t('Document')} ${num}`}
                              </legend>
                              <DocumentUploadField
                                inputFile={formData[`document0${num}ISfile`]}
                                uploadFile={f =>
                                  uploadFile(f, `document0${num}ISfile`)
                                }
                                onPrint={f =>
                                  onViewDoc(f, `document0${num}ISfile`)
                                }
                                removeFile={() =>
                                  removeFile(`document0${num}ISfile`)
                                }
                              />
                            </fieldset>
                          </Col>
                        ))}
                      </Row>
                    </Tab>
                    <Tab eventKey="renders" title="Renders" className="p-3">
                      <Row className="mb-3">
                        {counts.map((num, index) => (
                          <Col lg={4} key={index} className="mb-2">
                            <fieldset
                              className={'form-legend-item-wrapper p-2'}
                            >
                              <legend className="item-title fs-0 ms-2 fw-semi-bold">
                                {`${t('Render')} ${num}`}
                              </legend>
                              <ImageUploadField
                                inputImage={formData[`render0${num}ISfile`]}
                                uploadFile={f =>
                                  uploadFile(f, `render0${num}ISfile`)
                                }
                                removeFile={() =>
                                  removeFile(`document0${num}ISfile`)
                                }
                              />
                            </fieldset>
                          </Col>
                        ))}
                      </Row>
                    </Tab>
                  </Tabs>
                </div>

                <Row className="mb-3">
                  <Col>
                    <fieldset className={'form-legend-item-wrapper'}>
                      <legend className="item-title fs-0 fw-semi-bold">
                        {t('Video URL')}
                      </legend>
                      <Form.Group className="mb-0">
                        <Form.Control
                          placeholder={''}
                          value={formData.video_url}
                          name="video_url"
                          onChange={handleChange}
                          type="text"
                        />
                      </Form.Group>
                    </fieldset>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <fieldset className={'form-legend-item-wrapper'}>
                      <legend className="item-title fs-0 fw-semi-bold">
                        {t('Link URL')}
                      </legend>
                      <Form.Group className="mb-0">
                        <Form.Control
                          placeholder={''}
                          value={formData.urlISplaintextbox}
                          name="urlISplaintextbox"
                          onChange={handleChange}
                          type="text"
                        />
                      </Form.Group>
                    </fieldset>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col>
                    <fieldset className={'form-legend-item-wrapper'}>
                      <legend className="item-title fs-0 fw-semi-bold">
                        {t('Contact Name')}
                      </legend>
                      <Form.Group className="mb-0">
                        <Form.Control
                          placeholder={''}
                          value={formData.contact}
                          name="contact"
                          onChange={handleChange}
                          type="text"
                        />
                      </Form.Group>
                    </fieldset>
                  </Col>
                  <Col>
                    <fieldset className={'form-legend-item-wrapper'}>
                      <legend className="item-title fs-0 fw-semi-bold">
                        {t('Contact Category')}
                      </legend>
                      <Form.Group className="mb-0">
                        <Form.Control
                          placeholder={''}
                          value={formData.category}
                          name="category"
                          onChange={handleChange}
                          type="text"
                        />
                      </Form.Group>
                    </fieldset>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col>
                    <fieldset className={'form-legend-item-wrapper'}>
                      <legend className="item-title fs-0 fw-semi-bold">
                        {t('Contact Web Address')}
                      </legend>
                      <Form.Group className="mb-0">
                        <Form.Control
                          placeholder={''}
                          value={formData.web_address}
                          name="web_address"
                          onChange={handleChange}
                          type="text"
                        />
                      </Form.Group>
                    </fieldset>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col>
                    <fieldset className={'form-legend-item-wrapper'}>
                      <legend className="item-title fs-0 fw-semi-bold">
                        {t('Date Start')}
                      </legend>
                      <Form.Group className="mb-0">
                        <Form.Control
                          placeholder={''}
                          value={formData.date_start}
                          name="date_start"
                          onChange={handleChange}
                          type="text"
                        />
                      </Form.Group>
                    </fieldset>
                  </Col>
                  <Col>
                    <fieldset className={'form-legend-item-wrapper'}>
                      <legend className="item-title fs-0 fw-semi-bold">
                        {t('Status')}
                      </legend>
                      <Form.Group className="mb-0">
                        <Form.Control
                          placeholder={''}
                          value={formData.status}
                          name="status"
                          onChange={handleChange}
                          type="text"
                        />
                      </Form.Group>
                    </fieldset>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col>
                    <fieldset className={'form-legend-item-wrapper'}>
                      <legend className="item-title fs-0 fw-semi-bold">
                        {t('Dimensions')}
                      </legend>
                      <Form.Group className="mb-0">
                        <Form.Control
                          placeholder={''}
                          value={formData.dimensions_net}
                          name="dimensions_net"
                          onChange={handleChange}
                          type="text"
                        />
                      </Form.Group>
                    </fieldset>
                  </Col>
                  <Col>
                    <fieldset className={'form-legend-item-wrapper'}>
                      <legend className="item-title fs-0 fw-semi-bold">
                        {t('Display Order')}
                      </legend>
                      <Form.Group className="mb-0">
                        <Form.Control
                          placeholder={''}
                          value={formData.orderNUM}
                          name="orderNUM"
                          onChange={handleChange}
                          type="text"
                        />
                      </Form.Group>
                    </fieldset>
                  </Col>
                </Row>

                {!isAdd && (
                  <>
                    <Row className="mt-4">
                      <div className="text-center mb-2 fs-1 fw-semi-bold">
                        {t('Sub-Content')}
                      </div>
                      <Flex justifyContent={'end'} alignItems={'center'}>
                        <div className="w-auto">
                          <Button
                            variant="primary"
                            type="button"
                            disabled={saving}
                            onClick={onAddSubContent}
                            className="w-auto"
                          >
                            {saving ? `${t('Saving')}...` : t('Add')}
                          </Button>
                        </div>
                      </Flex>
                      {loadingSub ? (
                        <Card.Body className="">
                          <Loading
                            style={{ marginTop: 50 }}
                            msg="Loading Sub Content..."
                          />
                        </Card.Body>
                      ) : (
                        <>
                          {subContents.length > 0 && (
                            <div className="extended-table">
                              <AdvanceTableWrapper
                                columns={subContentColumns}
                                data={subContentTableData}
                                sortable
                                perPage={subContentTableData.length}
                              >
                                <AdvanceTable
                                  table
                                  headerClassName="bg-200 text-700 align-middle fs-0 custom-header text-center"
                                  rowClassName="align-middle white-space-nowrap fs-0"
                                  tableProps={{
                                    bordered: true,
                                    striped: true,
                                    className: 'fs--1 mb-0'
                                  }}
                                />
                              </AdvanceTableWrapper>
                            </div>
                          )}
                        </>
                      )}
                    </Row>

                    <Row className="mt-4">
                      <div className="text-center mb-2 fs-1 fw-semi-bold">
                        {t('Related Items')}
                      </div>
                      <Flex justifyContent={'end'} alignItems={'center'}>
                        <div className="w-auto">
                          <Button
                            variant="primary"
                            type="button"
                            onClick={onAddRelatedItem}
                            className="w-auto"
                          >
                            {t('Add')}
                          </Button>
                        </div>
                      </Flex>
                      {loadingRelated ? (
                        <Card.Body className="">
                          <Loading
                            style={{ marginTop: 50 }}
                            msg="Loading Related Items..."
                          />
                        </Card.Body>
                      ) : (
                        <>
                          {relatedItems.length > 0 && (
                            <div className="extended-table">
                              <AdvanceTableWrapper
                                columns={relatedItemsColumns}
                                data={relatedItemsTableData}
                                sortable
                                pagination
                                perPage={10}
                              >
                                <AdvanceTable
                                  table
                                  headerClassName="bg-200 text-700 align-middle fs-0 custom-header text-center"
                                  rowClassName="align-middle white-space-nowrap fs-0"
                                  tableProps={{
                                    bordered: true,
                                    striped: true,
                                    className: 'fs--1 mb-0'
                                  }}
                                />
                              </AdvanceTableWrapper>
                            </div>
                          )}
                        </>
                      )}
                    </Row>
                  </>
                )}

                <div className="text-end mt-6">
                  <Button variant="primary" type="submit" disabled={saving}>
                    {saving ? `${t('Saving')}...` : btnCaption}
                  </Button>
                </div>
              </Form>
            </fieldset>
          )}
        </Modal.Body>
      </Modal>
      {showDeleteSubContentModal && (
        <SubContentDeleteModal
          show={showDeleteSubContentModal}
          _id={selectedSubContent._id}
          handleClose={handleDeleteSubContentModalClose}
          success={updateSubContentSuccess}
        />
      )}
      {showAddSubContentModal && (
        <SubContentAddModal
          show={showAddSubContentModal}
          contentId={contentId}
          categories={categories}
          handleClose={handleAddSubContentModalClose}
          success={updateSubContentSuccess}
        />
      )}
      {showDeleteRelatedItemModal && (
        <RelatedItemDeleteModal
          show={showDeleteRelatedItemModal}
          _id={selectedRelatedItem._id}
          handleClose={handleDeleteRelatedItemModalClose}
          success={updateRelatedItemSuccess}
        />
      )}
      {showAddRelatedItemModal && (
        <RelatedItemAddModal
          show={showAddRelatedItemModal}
          contentId={contentId}
          existingOptions={relatedItems}
          handleClose={handleAddRelatedItemModalClose}
          success={updateRelatedItemSuccess}
        />
      )}
    </>
  );
};

ContentEditModal.propTypes = {
  show: PropTypes.bool,
  isAdd: PropTypes.bool,
  categories: PropTypes.array,
  contentId: PropTypes.string,
  parentCategoryId: PropTypes.string,
  handleClose: PropTypes.func,
  success: PropTypes.func
};

export default ContentEditModal;
