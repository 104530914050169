export let API_BASE_URL = `https://skytoolkit.blueboxonline.co.uk`;
if (
  window.location.host.indexOf('localhost') > -1 ||
  window.location.host.indexOf('dev') > -1
) {
  API_BASE_URL = `https://skytoolkit-dev.blueboxonline.co.uk`;
}

export const API_VERSION = `v1`;
export const API_ENDPOINT_PREFIX = `${API_BASE_URL}/api/${API_VERSION}`;

export default {
  settings: `${API_ENDPOINT_PREFIX}/app/settings`,
  signup: `${API_ENDPOINT_PREFIX}/app/signup`,
  quickLinks: `${API_ENDPOINT_PREFIX}/app/quick_links`,
  getPDF: url => `${API_ENDPOINT_PREFIX}/app/getPDF?pdfURL=${url}`,
  getUsersEndpoint: type =>
    `${API_ENDPOINT_PREFIX}/app/users?user_type=${type}`,
  addUserEndpoint: `${API_ENDPOINT_PREFIX}/app/users`,
  getEditUserEndpoint: id => `${API_ENDPOINT_PREFIX}/app/users/${id}`,
  login: `${API_ENDPOINT_PREFIX}/users/login`,
  session: `${API_ENDPOINT_PREFIX}/users/session`,
  otp: `${API_ENDPOINT_PREFIX}/users/otp`,
  forgotPassword: `${API_ENDPOINT_PREFIX}/users/forgot-password`,
  resetPassword: `${API_ENDPOINT_PREFIX}/users/reset-password`,
  logout: `${API_ENDPOINT_PREFIX}/users/logout`,
  userProfile: `${API_ENDPOINT_PREFIX}/users/profile`,
  authTokenEndpoint: `${API_ENDPOINT_PREFIX}/oauth/token`,
  authRevokeEndpoint: `${API_ENDPOINT_PREFIX}/oauth/revoke`,
  getEndpointWithSuffix: suffix => `${API_ENDPOINT_PREFIX}${suffix}`,
  getPageSchemaEndpoint: routeKey => `${API_ENDPOINT_PREFIX}/pages/${routeKey}`,
  getDataModuleSchemaEndpoint: (mn, action, id) =>
    `${API_ENDPOINT_PREFIX}/schemas/${mn}/${action}${id ? `?id=${id}` : ''}`,
  getModuleDataEndpoint: mn => `${API_ENDPOINT_PREFIX}/module/${mn}`,
  getDropTableEndpoint: mn => `${API_ENDPOINT_PREFIX}/module/${mn}/table`,
  getDropColumnEndpoint: mn => `${API_ENDPOINT_PREFIX}/module/${mn}/columns`,
  getDataAddEndpoint: mn => `${API_ENDPOINT_PREFIX}/module/${mn}`,
  getDataItemViewEndpoint: (mn, id) =>
    `${API_ENDPOINT_PREFIX}/module/${mn}/${id}`,
  getDataItemEditEndpoint: (mn, id) =>
    `${API_ENDPOINT_PREFIX}/module/${mn}/${id}`,
  getDataItemDeleteEndpoint: (mn, id) =>
    `${API_ENDPOINT_PREFIX}/module/${mn}/${id}`,
  getModuleSettingsEndpoint: mn =>
    `${API_ENDPOINT_PREFIX}/module/${mn}/settings`,
  getModuleSettingUpdateEndpoint: (mn, setting) =>
    `${API_ENDPOINT_PREFIX}/module/${mn}/settings/${setting}`,
  getDefaultDataEndpoint: mn =>
    `${API_ENDPOINT_PREFIX}/module/${mn}/table/default-data`,
  getNotificationClickEndpoint: id =>
    `${API_ENDPOINT_PREFIX}/users/notifications/${id}/read`,
  getMoreNotificationEndpoint: (offset = 5) =>
    `${API_ENDPOINT_PREFIX}/users/notifications?offset=${offset}`,
  fontAwesomeIcons: `${API_ENDPOINT_PREFIX}/fontawesome-icon-list`,
  getLookupEndpoint: suffix => `${API_ENDPOINT_PREFIX}${suffix}`,
  getBatchEndpointForSort: mn => `${API_ENDPOINT_PREFIX}/module/${mn}/batch`
};
