import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Navbar } from 'react-bootstrap';
import classNames from 'classnames';
import AppContext from 'context/Context';
import Logo from 'components/common/Logo';
import AlertModal from 'components/common/AlertModal';
import { useSelector, useDispatch } from 'react-redux';
import { setError } from 'redux/slicers/appsettingSlice';
import TopNavRightSideNavItem from './TopNavRightSideNavItem';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// import { getIcon } from 'helpers/utils';
// import Flex from 'components/common/Flex';
import useMobileScreenSize from 'hooks/useMobileScreenSize';

const NavbarTop = () => {
  const isMobile = useMobileScreenSize();

  const {
    config: { navbarCollapsed },
    setConfig
  } = useContext(AppContext);

  const initError = {
    isShow: false,
    title: '',
    message: ''
  };
  const error = useSelector(state => state.appsetting.error) || initError;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const isChat = pathname.includes('chat');

  const [showDropShadow, setShowDropShadow] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const btnRef = useRef();

  const { t } = useTranslation();
  const handleBurgerMenu = () => {
    setConfig('navbarCollapsed', !navbarCollapsed);
    setShowMenu(!showMenu);
  };

  const setDropShadow = () => {
    const el = document.documentElement;
    if (el.scrollTop > 0) {
      setShowDropShadow(true);
    } else {
      setShowDropShadow(false);
    }
  };

  const onModalClosed = () => {
    dispatch(
      setError({
        isShow: false,
        title: '',
        message: ''
      })
    );
  };

  const onBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    window.addEventListener('scroll', setDropShadow);
    return () => window.removeEventListener('scroll', setDropShadow);
  }, []);

  return (
    <>
      <Navbar
        className={classNames(
          'navbar-glass fs--1 navbar-top sticky-kit',
          { 'px-6': !isMobile, 'ps-6': isMobile },
          {
            'navbar-glass-shadow': showDropShadow && !isChat,
            expanded: showMenu
          }
        )}
        expand={false}
      >
        <NavbarTopElements
          navbarCollapsed={navbarCollapsed}
          handleBurgerMenu={handleBurgerMenu}
          btnRef={btnRef}
        />
      </Navbar>
      <AlertModal
        isShow={error.isShow}
        title={error.title}
        message={error.message}
        handleClose={onModalClosed}
      />
    </>
  );
};

const NavbarTopElements = () => {
  const isMobile = useMobileScreenSize();

  return (
    <div
      className={classNames(
        'd-flex justify-content-between align-items-center w-100',
        { 'ps-3': !isMobile }
      )}
    >
      <Logo at="navbar-top" width={160} id="topLogo" isNavbar={true} />
      <TopNavRightSideNavItem />
    </div>
  );
};

NavbarTopElements.propTypes = {
  navbarPosition: PropTypes.string,
  navbarCollapsed: PropTypes.bool
};
export default NavbarTop;
