import subCategoriesSlice from './slicers/subCategoriesSlice';
import authReducer from './slicers/authSlice';
import appSettingReducer from './slicers/appsettingSlice';
import contenSlice from './slicers/contentSlice';
import { configureStore } from '@reduxjs/toolkit';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    appsetting: appSettingReducer,
    subCategories: subCategoriesSlice,
    content: contenSlice
  }
});
