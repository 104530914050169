import React, { useState, useEffect, useRef, useMemo } from 'react';
import PageTitle from 'components/common/PageTitle';
import StoreSliderComponent from './StoreSliderComponent';
import PlanViewercomponent from './PlanViewercomponent';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setError } from 'redux/slicers/appsettingSlice';
import axios from 'axios';
import endpoint from 'services/endpoint';
import Loading from 'components/loading';
import { Button, Card } from 'react-bootstrap';
import StoreSubCategoryWrapper from './StoreSubCategoryWrapper';
import placeholderImage from 'assets/img/placeholder.png';
import { getErrorMesssage } from 'services/axiosHelpers';
import Flex from 'components/common/Flex';
import EditContentModal from 'components/pages/admin/modals/ContentEditModal';
// import RelatedItems from 'components/common/RelatedItems';
import { getYoutubeURL } from 'helpers/utils';
import ProjectHistory from './ProjectHistory';
import { replaceDashesWithSpaces } from 'helpers/utils';
import {
  updateElementContentId,
  updateElementContentName
} from 'redux/slicers/subCategoriesSlice';

const StorePage = () => {
  const _isMounted = useRef(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const path = window.location.pathname;

  const { countryName: cName, storeName: sName, kind: _kind } = useParams();
  const countryName = cName && replaceDashesWithSpaces(cName);
  const storeName = sName && replaceDashesWithSpaces(sName);
  const kind = _kind && replaceDashesWithSpaces(_kind);
  const { translations } = useSelector(state => state.appsetting);
  const { languageCode } = useSelector(state => state.subCategories);

  const fileCounts = 9;
  const category_tree = useSelector(state => state.appsetting.category_tree);
  const sub_branches =
    category_tree.find(c => c.name === 'Dashboard').sub_branches || [];
  const shops = sub_branches.find(c => c.name === kind).sub_branches;
  const allCountriesStores =
    shops.find(c => c.name === 'Portfolio').sub_branches || [];

  const project_sub_categories = category_tree.find(
    c => c.name === 'Project Sub Categories'
  );
  const proj_sub_branches = project_sub_categories
    ? project_sub_categories.sub_branches || []
    : [];

  const subcategories = useMemo(() => {
    let result = [];
    if (proj_sub_branches.length > 0) {
      result = proj_sub_branches.map(item => {
        return {
          _id: item._id,
          name: item.name,
          url: item.url,
          icon: item.image01ISfile ?? placeholderImage
        };
      });
    }
    return result;
  }, [proj_sub_branches]);

  const [loading, setLoading] = useState(false);
  const [loadingRelated, setLoadingRelated] = useState(false);
  const [contentId, setContentId] = useState(null);
  const [relatedItems, setRelatedItems] = useState([]);
  const [title, setTitle] = useState(storeName);
  const [subTitle, setSubTitle] = useState(countryName);
  const initSliderData = {
    title: 'Territory Delivery',
    slides: []
  };
  const [topSliderData, setTopSliderData] = useState(initSliderData);
  const [bottomSliderData, setBottomSliderData] = useState(initSliderData);
  const [plan, setPlan] = useState(null);
  const [video, setVideo] = useState(null);
  const [subContentTree, setSubContentTree] = useState([]);
  /**
   * ==========Edit content start
   */
  const appsetting = useSelector(state => state.appsetting);
  const inline_editing = appsetting.inline_editing;
  const [parentCategoryId, setParentCategoryId] = useState('');
  const [showEditContentModal, setShowEditContentModal] = useState(false);
  const handleEditContentModalClose = () => setShowEditContentModal(false);

  const getRelatedItems = async contentId => {
    try {
      _isMounted.current && setLoadingRelated(true);
      const ep = `${endpoint.getModuleDataEndpoint(
        'bb_q4sg_content_related'
      )}?contentISbb_q4sg_contentID=${contentId}`;
      const res = await axios.get(ep);
      if (res.data && res.data.list) {
        setRelatedItems(res.data.list);
      }
    } catch (err) {
      const message = getErrorMesssage(err);
      _isMounted.current &&
        dispatch(
          setError({
            isShow: true,
            title: 'Error',
            message
          })
        );
    } finally {
      _isMounted.current && setLoadingRelated(false);
    }
  };

  /**
   * =========Edit content end
   */

  const getContent = async _id => {
    try {
      _isMounted.current && setLoading(true);
      const ep = endpoint.getDataItemViewEndpoint('bb_q4sg_content', _id);
      const contentRes = await axios.get(ep);
      setSubContentTree(contentRes.data.sub_content_tree);
      dispatch(updateElementContentId(_id));
      _isMounted.current && setUpStoreData(contentRes.data);
      _isMounted.current && setTitle(contentRes.data.name);
      _isMounted.current &&
        setParentCategoryId(
          contentRes.data.category01ISbb_q4sg_content_categoriesID
        );
      _isMounted.current &&
        setSubTitle(
          contentRes.data.category01ISbb_q4sg_content_categoriesID_display
        );
    } catch (err) {
      const message = getErrorMesssage(err);
      dispatch(
        setError({
          isShow: true,
          title: 'Error',
          message
        })
      );
    } finally {
      _isMounted.current && setLoading(false);
    }
  };

  const getStoreFromState = async selectedCountry => {
    const s = selectedCountry.content_list.find(s => s.name === storeName);
    // console.log('selectedCountry', selectedCountry);
    if (s && s._id) {
      setContentId(s._id);
      await getContent(s._id);
      await getRelatedItems(s._id);
    }
  };

  const setup = () => {
    const selectedCountry = allCountriesStores.find(
      c => c.name === countryName
    );
    if (selectedCountry && selectedCountry.content_list) {
      getStoreFromState(selectedCountry);
    }
  };

  const setUpStoreData = async storeData => {
    /**
     * Top slider
     */
    let topSlides = [];
    for (let index = 1; index < fileCounts; index++) {
      if (storeData && storeData[`image0${index}ISfile`]) {
        const slide = {
          name: storeData[`image0${index}ISfile`]?.filename,
          image: storeData[`image0${index}ISfile`]?.uri
        };
        topSlides = [...topSlides, slide];
      }
    }
    const inTopSliderData = {
      title: 'Territory Delivery',
      slides: topSlides
    };

    setTopSliderData(inTopSliderData);

    /**
     * Bottom
     */
    let bottomSlides = [];
    for (let index = 1; index < fileCounts; index++) {
      if (storeData && storeData[`render0${index}ISfile`]) {
        const slide = {
          name: storeData[`render0${index}ISfile`]?.filename,
          image: storeData[`render0${index}ISfile`]?.uri
        };
        bottomSlides = [...bottomSlides, slide];
      }
    }
    const inBottomSliderData = {
      title: 'Store Visuals',
      slides: bottomSlides
    };
    setBottomSliderData(inBottomSliderData);

    /**
     * Plan
     */
    setPlan(storeData.document01ISfile);

    setVideo(storeData.video_url);
  };

  /**
   * ==========Edit content start ============
   */
  const modifyCategoryOnlyStructure = categories => {
    let result = [];
    if (categories && categories.length > 0) {
      categories.forEach(category => {
        const children = modifyCategoryOnlyStructure(category.sub_branches);
        const newCate = {
          name: category.name,
          id: category._id,
          children
        };
        result = [...result, newCate];
      });
    }
    return result;
  };

  const categoriesOnly = useMemo(() => {
    let result = modifyCategoryOnlyStructure(category_tree);
    return result;
  }, [category_tree]);

  const onEdit = () => {
    setShowEditContentModal(true);
  };

  const updateSuccess = () => {
    setup();
  };
  /**
   * =========Edit content end =================
   */

  useEffect(() => {
    _isMounted.current = true;
    setup();
    return () => {
      _isMounted.current = false;
    };
  }, [category_tree, path]);

  const translatedTitle = useMemo(() => {
    const result =
      languageCode === 'GB'
        ? title
        : translations?.[contentId]?.[languageCode]?.name || title;
    dispatch(updateElementContentName(title));
    return result;
  }, [languageCode, contentId, title, translations]);

  return (
    <div>
      <div className="text-center fw-semi-bold">{t(subTitle)}</div>
      <PageTitle title={translatedTitle} />
      {inline_editing && (
        <Flex justifyContent="end" alignItems="center" className="">
          <div className="button-wrapper">
            <Button color="primary" className="w-100" onClick={onEdit}>
              {t('Edit')}
            </Button>
          </div>
        </Flex>
      )}
      <div className="page-content pt-6 mb-6">
        {loading ? (
          <Card.Body className="">
            <Loading style={{ marginTop: 50 }} msg="Loading Data..." />
          </Card.Body>
        ) : (
          <>
            {topSliderData.slides.length > 0 && (
              <div className="mb-6">
                <StoreSliderComponent data={topSliderData} />
              </div>
            )}

            {contentId && subcategories.length > 0 && (
              <div className="mb-6">
                <StoreSubCategoryWrapper
                  contentId={contentId}
                  subCategories={subcategories}
                  subContentTree={subContentTree}
                  relatedItems={relatedItems}
                />
              </div>
            )}

            {plan && (
              <div className="mb-6">
                <PlanViewercomponent file={plan} />
              </div>
            )}

            {bottomSliderData.slides.length > 0 && (
              <div className="mb-6">
                <StoreSliderComponent data={bottomSliderData} />
              </div>
            )}

            <ProjectHistory />

            {video && (
              <div className="mb-6">
                <iframe
                  src={getYoutubeURL(video)}
                  width="100%"
                  height={'500px'}
                />
              </div>
            )}
          </>
        )}
      </div>
      {showEditContentModal && (
        <EditContentModal
          show={showEditContentModal}
          isAdd={false}
          contentId={contentId}
          categories={categoriesOnly}
          parentCategoryId={parentCategoryId}
          handleClose={handleEditContentModalClose}
          success={updateSuccess}
        />
      )}
    </div>
  );
};

export default StorePage;
