import PropTypes from 'prop-types';
import React, { useState } from 'react';
import './styles.css';
import { Button, Col, Row, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import endpoint from 'services/endpoint';
import { useDispatch } from 'react-redux';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { getErrorMesssage } from 'services/axiosHelpers';
import { dummyText } from 'data/dummy/dummy';
import Overlay from 'components/common/Overlay';
import InputMask from 'react-input-mask';
import { setError } from 'redux/slicers/appsettingSlice';

const ContactUsForm = () => {
  const moduleName = 'bb_q4sg_forms';
  const [isWorking, setIsWorking] = useState(false);
  const [token, setToken] = useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // State
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    message: ''
  });

  // Handler
  const handleSubmit = async e => {
    e.preventDefault();
    if (!token) {
      dispatch(
        setError({
          isShow: true,
          title: 'reCAPTCHA Error',
          message: 'Please make sure that you are not a robot.'
        })
      );
      return;
    }
    setIsWorking(true);
    try {
      /**
       * Check session
       */
      const ep = `${endpoint.getDataAddEndpoint(moduleName)}`;
      const tempData = {
        name: `${formData.firstName} ${formData.lastName}`,
        email: formData.email,
        mobile: formData.mobile,
        message: formData.message
      };
      const dataISsmallplaintextbox = JSON.stringify(tempData);
      const data = {
        name: 'Contact Form',
        dataISsmallplaintextbox
      };
      const contactResponse = await axios.post(ep, data);
      console.log('....contactResponse:', contactResponse);
      if (contactResponse.data.error) {
        const message = getErrorMesssage(contactResponse.data.error);
        dispatch(
          setError({
            isShow: true,
            title: 'Error',
            message
          })
        );
      } else {
        dispatch(
          setError({
            isShow: true,
            title: t('Submitted successfully!'),
            message: t(
              'Thank you for your query, a member of the Sky team will contact you shortly'
            )
          })
        );
      }
      setIsWorking(false);
    } catch (err) {
      console.log('----contact err--------', err);
      const message = getErrorMesssage(err);
      dispatch(
        setError({
          isShow: true,
          title: 'Error',
          message
        })
      );
      setIsWorking(false);
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      <fieldset className={'form-legend-wrapper pb-6 px-4'}>
        {/*<legend className="item-title fs-1 fw-semi-bold">
          {t('Contact Us')}
        </legend>*/}
        <Form onSubmit={handleSubmit} className="px-6 pt-4">
          <div className="mb-4">
            If you have any queries, please complete the below form and a member
            of the Sky team will get back to you shortly
          </div>
          <Row>
            <Col>
              <fieldset className={'form-legend-item-wrapper mb-4'}>
                <legend className="item-title fs-1 fw-semi-bold">
                  {t('First Name')}
                </legend>
                <Form.Group className="mb-0">
                  <Form.Control
                    placeholder={''}
                    value={formData.firstName}
                    name="firstName"
                    onChange={handleFieldChange}
                    type="text"
                  />
                </Form.Group>
              </fieldset>
            </Col>
            <Col>
              <fieldset className={'form-legend-item-wrapper mb-4'}>
                <legend className="item-title fs-1 fw-semi-bold">
                  {t('Last Name')}
                </legend>
                <Form.Group className="mb-0">
                  <Form.Control
                    placeholder={''}
                    value={formData.lastName}
                    name="lastName"
                    onChange={handleFieldChange}
                    type="text"
                  />
                </Form.Group>
              </fieldset>
            </Col>
          </Row>

          <Row>
            <Col>
              <fieldset className={'form-legend-item-wrapper mb-4'}>
                <legend className="item-title fs-1 fw-semi-bold">
                  {t('Email')}
                </legend>
                <Form.Group className="mb-0">
                  <Form.Control
                    placeholder={''}
                    value={formData.email}
                    name="email"
                    onChange={handleFieldChange}
                    type="email"
                  />
                </Form.Group>
              </fieldset>
            </Col>
            <Col>
              <fieldset className={'form-legend-item-wrapper'}>
                <legend className="item-title fs-1 fw-semi-bold">
                  {t('Mobile')}
                </legend>
                <Form.Group className="mb-0">
                  <InputMask
                    className="custom-input-mask"
                    mask="+99 (999) 999-9999"
                    maskChar="_"
                    type="text"
                    value={formData.mobile}
                    name="mobile"
                    onChange={handleFieldChange}
                    // className={`form-control ${
                    //   !!errors[field?.name] && 'is-invalid'
                    // }`}
                    placeholder="+11 (123) 456-7890"
                  />
                </Form.Group>
                {/* <Form.Control
                    placeholder={''}
                    value={formData.mobile}
                    name="mobile"
                    onChange={handleFieldChange}
                    type="text"
                  /> */}
              </fieldset>
            </Col>
          </Row>

          <Row>
            <Col>
              <fieldset className={'form-legend-item-wrapper mb-4'}>
                <legend className="item-title fs-1 fw-semi-bold">
                  {t('Message')}
                </legend>
                <Form.Group className="mb-0">
                  <Form.Control
                    placeholder={''}
                    value={formData.message}
                    name="message"
                    onChange={handleFieldChange}
                    as="textarea"
                  />
                </Form.Group>
              </fieldset>
            </Col>
          </Row>

          <Row>
            <Col>
              <GoogleReCaptcha
                onVerify={token => {
                  setToken(token);
                }}
              />
            </Col>
          </Row>

          <Form.Group>
            <Button
              type="submit"
              color="primary"
              className="mt-3"
              disabled={!formData.email || isWorking}
            >
              {/* {isWorking && <Spinner animation="border" size="sm"
              style={{width:'18px', height:'18px', marginRight:'8px'}}/>} */}
              <span>{t('Submit')}</span>
            </Button>
          </Form.Group>
        </Form>
        <Overlay isVisible={isWorking} />
      </fieldset>
    </>
  );
};

ContactUsForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

ContactUsForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default ContactUsForm;
