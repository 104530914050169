export const version = '3.8.0';
export const navbarBreakPoint = 'xl';
export const topNavbarBreakpoint = 'lg';
export const settings = {
  isFluid: false,
  isRTL: false,
  isDark: false,
  navbarPosition: 'vertical',
  showBurgerMenu: true,
  currency: '$',
  isNavbarVerticalCollapsed: true,
  navbarStyle: 'transparent'
};

export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };
