import { useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';

import Flex from 'components/common/Flex';

const NewsForm = ({ close, prev }) => {
  const handleBack = () => {
    prev();
    close();
  };

  const handleSubmit = async e => {
    e.preventDefault();
  };

  return (
    <Form onSubmit={handleSubmit} className="px-6 pt-4">
      <Flex className="gap-5">
        <Button variant="primary" type="submit">
          Submit For Approval
        </Button>
        <Button variant="primary" type="button" onClick={handleBack}>
          Back
        </Button>
      </Flex>
    </Form>
  );
};

export default NewsForm;
