import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import Main from './Main';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { reCAPTCHA_SITE_KEY } from './services/constants';
import { setAxiosDefaultHeader } from './services/axiosHelpers';
import 'helpers/initFA';
import './language/i18n';
import './assets/fonts/SKYTEXT-REGULAR.TTF';

setAxiosDefaultHeader(true);
const root = ReactDOM.createRoot(document.getElementById('main'));

root.render(
  <Provider store={store}>
    <Router basename={process.env.PUBLIC_URL}>
      <Main>
        <GoogleReCaptchaProvider reCaptchaKey={reCAPTCHA_SITE_KEY}>
          <App />
        </GoogleReCaptchaProvider>
      </Main>
    </Router>
  </Provider>
);
