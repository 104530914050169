import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, Row, Col } from 'react-bootstrap';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { setError } from 'redux/slicers/appsettingSlice';
import axios from 'axios';
import endpoint from 'services/endpoint';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DocumentUploadField from 'components/common/file-upload/DocumentUploadField';
import Treeview from 'components/common/tree-view/TreeviewSelectCatgory';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toolbarConfig } from 'services/constants';
import { toast } from 'react-toastify';
import { useMemo } from 'react';
import { getErrorMesssage } from 'services/axiosHelpers';

const SubContentAddModal = ({
  show,
  contentId,
  categories,
  handleClose,
  success
}) => {
  const treeCategories = useMemo(() => {
    let result = [];
    if (categories && categories.length > 0) {
      const projSubcat = categories.find(
        c => c.name === 'Project Sub Categories'
      );
      if (projSubcat && projSubcat.children) {
        result = projSubcat.children;
      }
    }
    return result;
  }, [categories]);
  const _isMounted = useRef(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);
  const title = t('New Sub Content');
  const btnCaption = t('Save');

  const mn = 'bb_q4sg_sub_content';
  const [selectedCategories, setSeletectedCategories] = useState([]);

  const [formData, setFormData] = useState({
    name: '',
    contentISbb_q4sg_contentID: contentId,
    sub_categoryISbb_q4sg_content_categoriesID: '',
    urlISplaintextbox: '',
    document01ISfile: null,
    document02ISfile: null
  });

  const [summary, setSummary] = useState('');

  const onChangeSummary = data => {
    const htmlData = data.toString('html');
    setSummary(data);
    const newFormData = {
      ...formData,
      urlISplaintextbox: htmlData
    };

    setFormData(newFormData);
  };

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const uploadFile = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value
    });
  };

  const setCategory = items => {
    if (items && items.length > 0) {
      setSeletectedCategories(items);
      setFormData({
        ...formData,
        sub_categoryISbb_q4sg_content_categoriesID: items[0]
      });
    } else {
      setSeletectedCategories([]);
      setFormData({
        ...formData,
        sub_categoryISbb_q4sg_content_categoriesID: ''
      });
    }
  };

  const showToastMessage = (type, message) => {
    if (type === 'success') {
      toast.success(message, {
        theme: 'colored'
      });
    } else {
      toast.error(message, {
        theme: 'colored'
      });
    }
  };

  const save = async () => {
    if (!formData.name) {
      showToastMessage('error', 'Name is required');
      return;
    }
    if (!formData.contentISbb_q4sg_contentID) {
      showToastMessage('error', 'Content ID is required');
      return;
    }
    if (!formData.sub_categoryISbb_q4sg_content_categoriesID) {
      showToastMessage('error', 'Category is required');
      return;
    }
    try {
      _isMounted.current && setSaving(true);
      let data = {
        name: formData.name,
        contentISbb_q4sg_contentID: formData.contentISbb_q4sg_contentID
      };

      const ep = endpoint.getDataAddEndpoint(mn);
      const res = await axios.post(ep, data);

      // console.log('-----post', data, ep);
      if (res.data && res.data._id) {
        await update(formData, res.data._id);
      }
    } catch (err) {
      const message = getErrorMesssage(err);
      _isMounted.current &&
        dispatch(
          setError({
            isShow: true,
            title: 'Error',
            message
          })
        );
    } finally {
      _isMounted.current && setSaving(false);
    }
  };

  const update = async (formData, id) => {
    if (!formData.name) {
      showToastMessage('error', 'Name is required');
      return;
    }
    try {
      _isMounted.current && setSaving(true);
      let data = formData;

      const ep = endpoint.getDataItemEditEndpoint(mn, id);
      const res = await axios.patch(ep, data);
      // console.log('-----patch', data, ep);

      if (res.data) {
        _isMounted.current && success();
        _isMounted.current && handleClose();
      }
    } catch (err) {
      const message = getErrorMesssage(err);
      _isMounted.current &&
        dispatch(
          setError({
            isShow: true,
            title: 'Error',
            message
          })
        );
    } finally {
      _isMounted.current && setSaving(false);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    await save();
  };

  const setup = () => {
    //
  };

  const onViewDoc = () => {
    //
  };

  useEffect(() => {
    _isMounted.current = true;
    if (show) {
      setup();
    }
    return () => {
      _isMounted.current = false;
    };
  }, [show]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        contentClassName="border-0"
        centered
        size="lg"
      >
        <Modal.Header className="bg-modal-header light border-bottom-0">
          {/* <Modal.Title as="h5" className="text-white">
            {title}
          </Modal.Title> */}
          <FalconCloseButton noOutline onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <fieldset className={'form-legend-wrapper pb-6 px-4'}>
            <legend className="item-title fs-1 fw-semi-bold">{t(title)}</legend>
            <Form onSubmit={handleSubmit} className="pt-4">
              <Row>
                <Col>
                  <fieldset className={'form-legend-item-wrapper mb-4'}>
                    <legend className="item-title fs-1 fw-semi-bold">
                      {t('Name')}
                    </legend>
                    <Form.Group className="mb-0">
                      <Form.Control
                        placeholder={''}
                        value={formData.name}
                        name="name"
                        onChange={handleChange}
                        type="text"
                      />
                    </Form.Group>
                  </fieldset>
                </Col>
              </Row>
              <Row>
                <Col>
                  <fieldset className={'form-legend-item-wrapper mb-4 px-4'}>
                    <legend className="item-title fs-1 fw-semi-bold">
                      {t('Refer to')}
                    </legend>
                    <Treeview
                      data={treeCategories}
                      selection
                      selectedItems={selectedCategories}
                      expanded={selectedCategories}
                      setSelectedItems={setCategory}
                    />
                  </fieldset>
                </Col>
              </Row>
              <Row>
                <Col>
                  <fieldset className={'form-legend-item-wrapper mb-4'}>
                    <legend className="item-title fs-1 fw-semi-bold">
                      {t('Text')}
                    </legend>
                    <Form.Group className="mb-0">
                      <ReactQuill
                        theme="snow"
                        value={summary}
                        onChange={onChangeSummary}
                      />
                    </Form.Group>
                  </fieldset>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col>
                  <fieldset className={'form-legend-item-wrapper mb-4 p-2'}>
                    <legend className="item-title fs-1 fw-semi-bold">
                      {`${t('PDF')}`}
                    </legend>
                    <DocumentUploadField
                      inputFile={formData[`document01ISfile`]}
                      uploadFile={f => uploadFile(f, `document01ISfile`)}
                      onPrint={f => onViewDoc(f, `document01ISfile`)}
                      removeFile={() => {}}
                    />
                  </fieldset>
                </Col>
                <Col>
                  <fieldset className={'form-legend-item-wrapper mb-4 p-2'}>
                    <legend className="item-title fs-1 fw-semi-bold">
                      {`${t('DWG')}`}
                    </legend>
                    <DocumentUploadField
                      inputFile={formData[`document02ISfile`]}
                      uploadFile={f => uploadFile(f, `document02ISfile`)}
                      onPrint={f => onViewDoc(f, `document02ISfile`)}
                      removeFile={() => {}}
                    />
                  </fieldset>
                </Col>
              </Row>

              <div className="text-end">
                <Button variant="primary" type="submit" disabled={saving}>
                  {saving ? `${t('Saving')}...` : btnCaption}
                </Button>
              </div>
            </Form>
          </fieldset>
        </Modal.Body>
      </Modal>
    </>
  );
};

SubContentAddModal.propTypes = {
  show: PropTypes.bool,
  contentId: PropTypes.string,
  categories: PropTypes.array,
  handleClose: PropTypes.func,
  success: PropTypes.func
};

export default SubContentAddModal;
