import axios from 'axios';

export const getApiBaseUrl = () => {
  return process.env.REACT_APP_BASE_REST_API_URL;
};

const https = axios.create({
  baseURL: getApiBaseUrl(),
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json; charset=utf-8'
  },
  withCredentials: true
});

export default https;
