import React, { useMemo, useRef, useEffect, useState } from 'react';
import PageTitle from 'components/common/PageTitle';
import { Col, Row } from 'react-bootstrap';
import NavItemComponent from 'components/common/NavItemComponent';
import { useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import ProductDocuments from 'components/pages/shops/ProductDocuments';
import placeholderImage from 'assets/img/placeholder.png';
//import Loading from 'components/loading';
import { setError } from 'redux/slicers/appsettingSlice';
import axios from 'axios';
import endpoint from 'services/endpoint';
import { useDispatch } from 'react-redux';
import { getErrorMesssage } from 'services/axiosHelpers';
import { replaceDashesWithSpaces } from 'helpers/utils';
import { useTranslation } from 'react-i18next';

const ShopSub = () => {
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const _isMounted = useRef(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [categoryId, setCategoryId] = useState(null);
  const {
    category: cat,
    subCategory1: sCat1,
    subCategory2: sCat2
  } = useParams();
  const category = cat && replaceDashesWithSpaces(cat);
  const subCategory1 = sCat1 && replaceDashesWithSpaces(sCat1);
  const subCategory2 = sCat2 && replaceDashesWithSpaces(sCat2);

  const title = subCategory2 ?? subCategory1;
  const category_tree = useSelector(state => state.appsetting.category_tree);
  const sub_branches =
    category_tree.find(c => c.name === 'Dashboard').sub_branches || [];
  const inShops =
    sub_branches.find(c => c.name === category).sub_branches || [];
  const shops = inShops.find(i => i.name === subCategory1).sub_branches || [];
  const mn = 'bb_q4sg_content_categories';

  const items = useMemo(() => {
    let result = [];
    if (!subCategory2) {
      result = shops.map(item => {
        return {
          ...item,
          title: item.name,
          url: `/Dashboard/${category}/${subCategory1}/${item.name}`,
          icon: item.image01ISfile ?? placeholderImage
        };
      });
    } else {
      const shop = shops.find(s => s.name === subCategory2);
      if (shop && shop.sub_branches) {
        result = shop.sub_branches.map(item => {
          return {
            ...item,
            title: item.name,
            url: `/Dashboard/${category}/${subCategory1}/${subCategory2}/${item.name}`,
            icon: item.image01ISfile ?? placeholderImage
          };
        });
      }
    }
    return result;
  }, [subCategory1, subCategory2, shops]);

  const contents = useMemo(async () => {
    let result = [];
    if (subCategory2) {
      const categoryInfo = shops.find(i => i.name === subCategory2);
      result = shops.find(i => i.name === subCategory2).content_list || [];
      console.log('=========subCategory2', categoryInfo);
      if (categoryInfo && categoryInfo._id) {
        setCategoryId(categoryInfo._id);
      }
      //const _id = subCategory2._id;
      //await getCategory(_id);
    } else {
      const categoryInfo = shops.find(
        i => i.name === replaceDashesWithSpaces(subCategory1)
      );
      result =
        inShops.find(i => i.name === replaceDashesWithSpaces(subCategory1))
          .content_list || [];
      console.log('=========subCategory1', categoryInfo);
      if (categoryInfo && categoryInfo._id) {
        setCategoryId(categoryInfo._id);
      }
      //const _id = subCategory1._id;
      //await getCategory(_id);
    }
    return result;
  }, [subCategory1, subCategory2]);

  const getCategory = async _id => {
    try {
      _isMounted.current && setLoading(true);
      const ep = `${endpoint.getDataItemViewEndpoint(mn, _id)}`;
      const res = await axios.get(ep);
      const infoData = res.data;
      console.log('=========infoDta', infoData);
      _isMounted.current && setLoading(false);

      if (infoData.error) {
        const message = getErrorMesssage(infoData.error);
        _isMounted.current &&
          dispatch(
            setError({
              isShow: true,
              title: 'Error',
              message
            })
          );
        return null;
      } else {
        // setupForm(infoData);
        return infoData;
      }
    } catch (err) {
      const message = getErrorMesssage(err);
      _isMounted.current &&
        dispatch(
          setError({
            isShow: true,
            title: 'Error',
            message
          })
        );
      _isMounted.current && setLoading(false);
      return null;
    }
  };

  const documentsData = useMemo(() => {
    return {
      title: 'Ways of Working',
      documents: contents
    };
  }, [contents]);

  useEffect(() => {
    _isMounted.current = true;
    if (categoryId === 'XXX') {
      console.log(loading);
      getCategory(categoryId);
    }
    return () => {
      _isMounted.current = false;
    };
  }, [categoryId]);

  const pagesUnderConstruction = [
    '/Dashboard/3rd-Party-&-Tactical/Archive',
    '/Dashboard/Mid-Mall/Archive',
    '/Dashboard/Shops/Archive'
  ];
  console.log(title, 'title-NavItemComponent');
  return (
    <div>
      <PageTitle title={title} />
      {pagesUnderConstruction.some(item => item === pathname) && (
        <div style={{ color: 'gray', textAlign: 'center', fontSize: '16pt' }}>
          {t('This section is under construction')}
          {/* This section is under construction, come back soon to discover more... */}
        </div>
      )}
      <div className="page-content pt-6 mb-6">
        {subCategory1 === 'Principles & Design Guidelines' && (
          <div style={{ color: 'gray', textAlign: 'center', fontSize: '16pt' }}>
            {t('This section is under construction')}
            {/* This section is under construction, come back soon to discover
            more... */}
          </div>
        )}
        {subCategory1 != 'Principles & Design Guidelines' &&
          items.length > 0 && (
            <Row className="mb-6">
              {items.map((item, index) => (
                <Col key={index} md="4" className="mb-4">
                  <NavItemComponent {...item} className="mb-4 h-100" />
                </Col>
              ))}
            </Row>
          )}

        {contents.length > 0 && <ProductDocuments data={documentsData} />}
      </div>
    </div>
  );
};

export default ShopSub;
