import React, { useState, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import NotificationDropdown from 'components/navbar/top/NotificationDropdown';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import { useSelector } from 'react-redux';
import { Nav } from 'react-bootstrap';
import SearchBox from './SearchBox';
import { topNavbarBreakpoint } from 'config';
import { getContentUrl, getAllContentsFormTree } from 'helpers/utils';
import useMobileScreenSize from 'hooks/useMobileScreenSize';

const TopNavRightSideNavItem = () => {
  const isMobile = useMobileScreenSize();
  const [showSearch, setShowSearch] = useState(false);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const category_tree = useSelector(state => state.appsetting.category_tree);
  const handleToggle = () => {
    setShowSearch(!showSearch);
  };

  const contentListingData = useMemo(() => {
    const contents = getAllContentsFormTree(category_tree, '', '');
    return contents;
  }, [category_tree]);

  const autoCompleteInitialItem = useMemo(() => {
    const contents = contentListingData.map(item => {
      return {
        ...item,
        id: item._id,
        key: item._id,
        url: getContentUrl(item.link, item.type)
      };
    });
    return contents;
  }, [contentListingData]);

  /*
  if(user.email=='testadmin@loyal2.com'){
    onLogOut();
  }

  const onLogOut = async () => {
    try {
      const logoutEp = `${endpoint.logout}`;
      const logoutResponse = await axios.get(logoutEp);
      console.log('logoutResponse', logoutResponse);
      localStorage.clear();
      dispatch(logout());
    } catch (err) {
      const message = getErrorMesssage(err);
      dispatch(
        setError({
          isShow: true,
          title: 'Error',
          message
        })
      );
    }
  };
  */

  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      {showSearch && (
        <Nav
          navbar
          className={`align-items-center d-none d-${topNavbarBreakpoint}-block`}
          as="ul"
        >
          <Nav.Item as="li">
            <SearchBox autoCompleteItem={autoCompleteInitialItem} />
          </Nav.Item>
        </Nav>
      )}
      {!isMobile && isAuthenticated && (
        <Nav.Item as={'li'}>
          <Nav.Link
            className="px-2 theme-control-toggle"
            onClick={handleToggle}
          >
            <div className="theme-control-toggle-label">
              <FontAwesomeIcon icon={'search'} className="fs-0" />
            </div>
          </Nav.Link>
        </Nav.Item>
      )}

      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
