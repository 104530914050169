import React from 'react';
import { Slide, toast, ToastContainer } from 'react-toastify';

const ToastifyNotification = ({ message, errorMessage }) => {
  const notify = errorMessage === true ? toast.error : toast.success;

  notify(message, {
    position: 'top-right',
    // autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
    transition: Slide,
    className: errorMessage ? 'custom-toast-error' : 'custom-toast-success'
  });

  return <ToastContainer />;
};

export default ToastifyNotification;
