import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Col, Row, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import endpoint from 'services/endpoint';
import { useDispatch, useSelector } from 'react-redux';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { getErrorMesssage } from 'services/axiosHelpers';
import { setError } from 'redux/slicers/appsettingSlice';

const SignupForm = () => {
  const dropdowns = useSelector(state => state.appsetting.dropdowns);
  const userOptionList = dropdowns.user_type;
  const [isWorking, setIsWorking] = useState(false);
  const [token, setToken] = useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // State
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    region: ''
  });

  // Handler
  const handleSubmit = async e => {
    e.preventDefault();
    if (!token) {
      dispatch(
        setError({
          isShow: true,
          title: 'reCAPTCHA Error',
          message: 'Please make sure that you are not a robot.'
        })
      );
      return;
    }
    setIsWorking(true);
    try {
      /**
       * Check session
       */
      const ep = `${endpoint.signup}`;
      const singupResponse = await axios.post(ep, formData);
      console.log('....singupResponse:', singupResponse);
      if (singupResponse.data.error) {
        const message = getErrorMesssage(singupResponse.data.error);
        dispatch(
          setError({
            isShow: true,
            title: 'Error',
            message
          })
        );
      } else {
        dispatch(
          setError({
            isShow: true,
            title: 'Success',
            message: 'You have signed up successfully! Thank you'
          })
        );
      }
      setIsWorking(false);
    } catch (err) {
      const message = getErrorMesssage(err);
      dispatch(
        setError({
          isShow: true,
          title: 'Error',
          message
        })
      );
      setIsWorking(false);
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      <fieldset className={'form-legend-wrapper pb-6 px-4'}>
        {/*<legend className="item-title fs-1 fw-semi-bold">
          {t('Request Access')}
        </legend>*/}
        <Form onSubmit={handleSubmit} className="px-6 pt-4">
          <div className="mb-4">{/*dummyText*/}</div>
          <Row>
            <Col>
              <fieldset className={'form-legend-item-wrapper mb-4'}>
                <legend className="item-title fs-1 fw-semi-bold">
                  {t('First Name')}
                </legend>
                <Form.Group className="mb-0">
                  <Form.Control
                    placeholder={''}
                    value={formData.first_name}
                    name="first_name"
                    onChange={handleFieldChange}
                    type="text"
                  />
                </Form.Group>
              </fieldset>
            </Col>
            <Col>
              <fieldset className={'form-legend-item-wrapper mb-4'}>
                <legend className="item-title fs-1 fw-semi-bold">
                  {t('Last Name')}
                </legend>
                <Form.Group className="mb-0">
                  <Form.Control
                    placeholder={''}
                    value={formData.last_name}
                    name="last_name"
                    onChange={handleFieldChange}
                    type="text"
                  />
                </Form.Group>
              </fieldset>
            </Col>
          </Row>

          <Row>
            <Col>
              <fieldset className={'form-legend-item-wrapper mb-4'}>
                <legend className="item-title fs-1 fw-semi-bold">
                  {t('Email')}
                </legend>
                <Form.Group className="mb-0">
                  <Form.Control
                    placeholder={''}
                    value={formData.email}
                    name="email"
                    onChange={handleFieldChange}
                    type="email"
                  />
                </Form.Group>
              </fieldset>
            </Col>
            <Col>
              <fieldset className={'form-legend-item-wrapper'}>
                <legend className="item-title fs-1 fw-semi-bold">
                  {t('Password')}
                </legend>
                <Form.Group className="mb-0">
                  <Form.Control
                    placeholder={''}
                    value={formData.password}
                    name="password"
                    onChange={handleFieldChange}
                    type="password"
                  />
                </Form.Group>
              </fieldset>
            </Col>
          </Row>

          <Row>
            <Col>
              <fieldset className={'form-legend-item-wrapper mb-4'}>
                <legend className="item-title fs-1 fw-semi-bold">
                  {t('User Type')}
                </legend>
                <Form.Group className="mb-0">
                  <Form.Control
                    as="select"
                    value={formData.user_type}
                    name="user_type"
                    onChange={handleFieldChange}
                    required
                    className="form-select"
                  >
                    <option value="">{'Select...'}</option>
                    {userOptionList.map((option, index) => (
                      <option value={option.name} key={index}>
                        {option.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </fieldset>
            </Col>
            <Col>
              <GoogleReCaptcha
                onVerify={token => {
                  setToken(token);
                }}
              />
            </Col>
          </Row>

          <Form.Group>
            <Button
              type="submit"
              color="primary"
              className="mt-3"
              disabled={!formData.email || isWorking}
            >
              {t('Submit')}
            </Button>
          </Form.Group>
        </Form>
      </fieldset>
    </>
  );
};

SignupForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

export default SignupForm;
